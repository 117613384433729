import React from 'react'
import '../../assets/css/style.css'
import '../Slider/slider.css'
import img from '../../Images/practice_area/constitutional_4.jpg'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom'

const Constitutional = () => {
    return (
        <>
            <section className='slider-container_prac' >
                <div className="">
                    <Zoom>
                    <img loading="lazy" src={img} alt="" />
                    </Zoom>
                </div>
            </section>
           
            <section id="about-top" className="py100  about_us_bg">
                <div className="container" >
                    <div className="row">

                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600" }}>
                                Constitutional Law
                            </h1>
                            <p style={{ padding: "2rem" }}>

                                <Fade duration={3000}>
                                    In the legal landscape of any nation, the Constitution stands as the bedrock, the paramount source of law that shapes the very foundation of its legal system. At Gaur Legum, we understand and uphold the sanctity of the Constitution, considering it not only as the law of the land but as the embodiment of our nation's values, principles, and aspirations. Our Advocates, with their extensive knowledge and experience in all areas of constitutional law, are dedicated to ensuring that all laws are in consonance with the principles enshrined in the Constitution.
                                    <br />
                                    <br />
                                    Our team of Advocates excels in a wide array of constitutional law areas, including, we are adept to review administrative actions for their compliance with constitutional principles. Ensuring that government actions are in line with the Constitution is a fundamental aspect of our practice.
                                    <br />
                                    <br />
                                    We possess the expertise to scrutinize and challenge laws that go beyond the powers granted by the Constitution. Our Advocates actively engage in upholding the constitutional limits of legislative authority.
                                    Our team offers in-depth analysis and interpretation of constitutional provisions to ensure their contemporary relevance and applicability.
                                    Protecting and enforcing fundamental rights guaranteed by the Constitution is a cornerstone of our practice. We are committed to ensuring that citizens' rights are safeguarded.
                                    We are well-prepared to challenge legislation that conflicts with the constitutional framework. Our Advocates employ their knowledge and experience to address legal issues related to constitutionality effectively.
                                    
                                    Our services extend beyond litigation. We offer advisory and opinion work on constitutional law issues, providing clients with qualified legal advice to navigate constitutional complexities.
                                    <br />
                                    <br />
                                    Our Advocates are well-versed in the provisions of the Indian Constitution, the applicable statutes, and relevant case law. This comprehensive understanding allows us to provide clients with qualified legal advice and representation in matters that intersect with constitutional law.
                                    <br />
                                    <br />
                                    At Gaur Legum, our dedication to constitutional law is not just professional; it is a commitment to upholding the values and principles that form the bedrock of our nation. We believe that the Constitution is a dynamic document, and its interpretation and application should reflect the evolving needs and aspirations of the people it serves.
                                    <br />
                                    <br />
                                    Constitutional law at Gaur Legum is a profound commitment to the principles that underpin our legal system. Our Advocates are here to ensure that the Constitution remains the guiding light in all legal matters and that every law and action is held up to the highest constitutional standards. When you choose Gaur Legum for your constitutional law needs, you are choosing a team that is not only well-versed in the law but is also deeply enthusiastic about upholding the essence of the Constitution in all its legal facets.

                                 </Fade>
                            </p>
                        </div>
                      
                    </div>
                </div>
            </section>
        </>
    )
}

export default Constitutional