
import React from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css'
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import "./assets/css/style.css"
import "./assets/css/color.css"
import "./assets/css/responsive.css"
import "./Style/blog.css"
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </>
);
