import React from 'react'
import '../../assets/css/style.css'
import '../Slider/slider.css'
import img from '../../Images/practice_area/intercountry_2.png'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const IntercountryLaws = () => {
    return (
        <>
            <section className='slider-container_prac' >
                <div className="">
                    <Zoom>
                    <img loading="lazy" src={img} alt="" />
                    </Zoom>
                </div>
            </section>
            
            <section id="about-top" className="py100  about_us_bg">
                <div className="container" >
                    <div className="row">

                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600" }}>
                                Intercountry Laws
                            </h1>
                            <p style={{ padding: "2rem" }}>
                                <Fade duration={3000}>
                                    At Gaur Legum, we take immense pride in our extensive experience and dedication to handling legal matters related to adoption, family law, and child custody. These practice areas are characterized by their deeply personal and often emotionally charged nature, and our team of Advocates is committed to providing compassionate, expert, and effective legal support to individuals and families.
                                    <br />
                                    <br />
                                    Our Advocates possess substantial experience in navigating the intricate rules governing inter-country adoptions. We have successfully obtained approvals for numerous inter-country adoptions, assisting families in bringing their adopted children to foreign nations in full compliance with the law. The legislation that governs inter-country adoptions is known for its complexity. Therefore, obtaining expert guidance is crucial. Any issue or misstep can have far-reaching consequences, potentially shattering the aspirations of adoptive parents. Our Advocates are dedicated to representing you throughout the entire adoption process, from start to finish. We handle all aspects of child adoption, including passport issuance and immigration, ensuring that the process is as smooth and stress-free as possible for our clients.
                                    <br />
                                    <br />
                                    In addition to our expertise in adoption proceedings, Gaur Legum boasts vast experience in handling marital and child custody issues. We provide legal advice and representation to both domestic and international clients dealing with marriage and family law problems. Our commitment is to ensure that Non-Resident Indians (NRIs) face no undue difficulties when initiating divorce actions in India or defending against cases brought against them. We understand the unique challenges faced by NRIs, considering their professional and family responsibilities. Our lawyers have successfully represented clients in courts across India, traveling to different states and cities to appear in court and make the necessary pleadings on behalf of our clients.
                                    <br />
                                    <br />
                                    At Gaur Legum, we recognize the sensitive and life-altering nature of legal matters related to adoption, family law, and child custody. Our commitment to our clients goes beyond legal expertise; it is deeply rooted in compassion and empathy. We are here to guide you through complex legal processes, ensuring that the best interests of your family are protected and prioritized.
                                    <br />
                                    <br />
                                    Adoption, family law, and child custody issues require a unique blend of legal expertise and compassion. When you choose Gaur Legum for your family-related legal needs, you are choosing a team that is not only well-versed in the intricacies of these areas but is also genuinely dedicated to your family's well-being and future. Your family's future is our top priority, and we are here to provide the unwavering support and guidance you need during these crucial life events.

                                    {/* Our Advocates also have substantial experience in adoption proceedings and the complex rules that govern inter-country adoptions. We have successfully obtained approval for a number of inter-country adoptions and assisted families in bringing their adopted kids to a foreign nation in accordance with the law. The legislation governing inter-country adoptions is extremely complicated for this reason it is usually essential to obtain the greatest expert guidance in cases of adoption because it affects the future of a kid, and any problem shatters the adoptive parents' aspirations completely. Our Advocates will represent you from the beginning to the finish of the process and will handle all aspects of child adoption, including passport issuance and immigration.
                         <br />
                         <br />
                         Furthermore, the Law Firm has vast experience with marital and child custody issues. Domestic and international customers have relied on our Advocates for legal advice and representation in marriage and family law problems. Our Law Firm assures that NRIs experience no difficulties in initiating a divorce action in India or defending a case brought against them while taking into mind their professional and family duties. Our lawyers have represented clients in all courts in India, and they travel to different states and towns to directly appear in court and make the necessary pleadings. */}
                                </Fade>
                            </p>
                        </div>
                      
                    </div>
                </div>
            </section>
        </>
    )
}

export default IntercountryLaws