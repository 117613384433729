import React from 'react'
import '../Style/style.css'
import img from '../Images/prac_area_1.png'
import { Col, Container } from 'reactstrap'
import ServiceCard from '../Components/Service/ServiceCard'
import Image1 from "../Images/PracticeAreaIcons/constituional.png"
import Image2 from '../Images/icons/services/land_1.png'
import Image3 from '../Images/PracticeAreaIcons/family-law.png'
import Image4 from '../Images/PracticeAreaIcons/criminal.png'
import Image5 from '../Images/PracticeAreaIcons/arbitration.png'
import Image6 from '../Images/PracticeAreaIcons/intellectual.png'
import Image7 from '../Images/PracticeAreaIcons/immigration-law.png'
import Image8 from '../Images/PracticeAreaIcons/employment-law.png'
import Image9 from '../Images/PracticeAreaIcons/corporate.png'
import Image10 from '../Images/PracticeAreaIcons/medical negligence.png'
import Image11 from '../Images/PracticeAreaIcons/banking.png'
import Image12 from '../Images/PracticeAreaIcons/consumer law.png'
import Image13 from '../Images/PracticeAreaIcons/mediate.png'
import Image14 from '../Images/PracticeAreaIcons/travel-and-tourism.png'
import Image15 from '../Images/PracticeAreaIcons/technology telecommunication.png'
import Zoom from 'react-reveal/Zoom'
const serviceData = [

    {
        imageUrl: Image1,
        title: "Constitutional issues",
        path: '/constitutional',
    },
    {
        imageUrl: Image2,
        title: "Land & Property Disputes",
        path: '/landandpropertydisputes',
    },
    {
        imageUrl: Image3,
        title: " Divorce & Family Laws ",
        path: '/familylaw',
    },
    {
        imageUrl: Image4,
        title: "Criminal Laws",
        path: '/criminallaw',
    },
    {
        imageUrl: Image5,
        title: "Arbitration & Mediation",
        path: '/arbitrationandmediation',
    },
    {
        imageUrl: Image6,
        title: "Intellectual Property Rights",
        path: '/intellectualpropertyrights',
    },
    {
        imageUrl: Image2,
        title: "Inter Country Laws",
        path: '/intercountrylaws',
    },
    {
        imageUrl: Image7,
        title: "Immigration Laws",
        path: '/immigrationlaw',
    },
    {
        imageUrl: Image8,
        title: "Employement & Service Matters",
        path: '/employmentandservicematters',
    },
    {
        imageUrl: Image9,
        title: " Corporate Affairs & Foreign Collaboration",
        path: '/corporateaffairsandforeign',
    },
    {
        imageUrl: Image10,
        title: "Medical Negligence",
        path: '/medicalnegligence',
    },
    {
        imageUrl: Image11,
        title: " Banking & Finance Laws",
        path: '/bankingandfinance',
    },
    {
        imageUrl: Image12,
        // title: "Consumer Law (Unfair Trade Practice, NCDRC, State and District Consumer Commissions)",
        title: "Consumer Laws",
        path: '/consumerlaw',
    },
    {
        imageUrl: Image13,
        title: "Media and Sports Laws",
        path: '/mediaandsports',
    },
    {
        imageUrl: Image14,
        title: "Tourism & Hospitality",
        path: '/tourismandhospitality',
    },
    {
        imageUrl: Image15,
        title: "Technology & Telecomunication",
        path: '/TechnologyAndTelecommunications',
    },
]

const Practicearea = () => {
    return (
        <>
            <section className='slider-container_prac_t ' style={{ background: '#143d53' }} >
                <Zoom>
                    <div>
                        <img loading="lazy" src={img} alt="Practice Area" />
                    </div>
                    <div className='slide-title top_p'>
                        <h1 >Practice Areas</h1>
                    </div>
                </Zoom>
            </section>
            <section className='service'>
                <Container className='container pt-5 pb-5 '>
                    <div className='struc_service'>
                        <>
                            {
                                serviceData.map((item, index) => <Col key={index} className='center d-flex justify-content-center align-items-center'>
                                    <ServiceCard item={item} />
                                </Col>)
                            }
                        </>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Practicearea