import React, { useEffect, useState } from "react";
import SliderContent from "./SliderContent";
import Dots from "./Dots";
import sliderImage from "./sliderImage";
import "./slider.css";
import Fade from 'react-reveal/Fade';
const len = sliderImage.length - 1;

function Slider(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div style={{ marginTop: "-10px", overflow: "hidden", background: '#143d53' }}>
      <Fade right   >
        <div className="slider-container" style={{ overflow: "hidden" }}>
          <SliderContent activeIndex={activeIndex} sliderImage={sliderImage} />
          {/* <Arrows
        prevSlide={() =>
          setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
        }
        nextSlide={() =>
          setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
        }
      /> */}
          <Dots
            activeIndex={activeIndex}
            sliderImage={sliderImage}
            onclick={(activeIndex) => setActiveIndex(activeIndex)}
          />
        </div>
      </Fade>
    </div>
  );
}

export default Slider;
