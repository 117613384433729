import React, { useState, useEffect } from "react";
import axios from "axios";
import BlogCard from "../components/BlogCard";
import img from '../../../../Images/prac_area_1.png'
import Zoom from 'react-reveal/Zoom'
const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  //get blogs
  const getAllBlogs = async () => {
    try {
      const { data } = await axios.get("/api/v1/blog/all-blog");
      if (data?.success) {
        setBlogs(data?.blogs);
      }
    } catch (error) {
      console.log(error);
    }
    
  };
  useEffect(() => {
    getAllBlogs();
  }, []);
  return (
    <>
      <section className='slider-container_prac_t ' style={{ background: '#143d53' }} >
                <Zoom>
                    <div>
                        <img loading="lazy" src={img} alt="Practice Area" />
                    </div>
                    <div className='slide-title top_p'>
                        <h1>Blogs</h1>
                    </div>
                </Zoom>
            </section>
    <div style={{minHeight:"100vh",  backgroundColor:"#143d53", color:'white' }}>
    <div className="mainCardForBlogs" style={{ display: "flex", flexWrap: "wrap"  }}>
  {blogs &&
    blogs.map((blog) => (
      <>
      <BlogCard
      key={blog?._id}
      id={blog?._id}
      isUser={localStorage.getItem("userId") === blog?.user?._id}
      title={blog?.title}
      description={blog?.description}
      image={blog?.image}
      // username={blog?.user?.username}
      // time={blog?.createdAt}
      className=""
      style={{ flex: "0 0 50%", maxWidth: "50%" }}
      />
      </>
      ))}
  <style jsx>{`
    @media (max-width: 850px) {
      .mainCardForBlogs {
        flex-direction: column;
      }
    }
    `}</style>

</div>

    </div>
    </>
  );
};

export default Blogs;
