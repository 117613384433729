import React from 'react'
import '../../assets/css/style.css'
import '../Slider/slider.css'
import img from '../../Images/practice_area/arbitration.png'
import Fade from 'react-reveal/Fade';

import Zoom from 'react-reveal/Zoom'
const ArbitrationAndMediation = () => {
    return (
        <>
            <section className='slider-container_prac' >
                <div className="">
                    <Zoom>
                        <img loading="lazy" src={img} alt="" />
                    </Zoom>
                </div>
            </section>
            
            <section id="about-top" className="py100  about_us_bg">
                <div className="container" >
                    <div className="row">

                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600" }}>
                                Arbitration And Mediation
                            </h1>
                            <p style={{ padding: "2rem", textAlign: "left" }}>


                                <Fade duration={3000}>

                                    Gaur Legum is proud to offer a team of highly skilled and knowledgeable Advocates who are adept at leading and assisting clients in the design of arbitration agreements, initiating arbitration proceedings, and successfully navigating the arbitration process. Arbitration stands as one of the most effective and widely embraced methods for resolving disputes in the global commercial sector. Our expertise extends to both International Commercial Arbitration and Domestic Arbitration, enabling us to serve the diverse needs of our clients.
                                    Arbitration is a preferred choice for businesses and individuals involved in international or domestic commercial disputes for several compelling reasons, Arbitration offers a streamlined and efficient process that allows for the swift resolution of disputes, reducing the time and costs associated with traditional litigation.

                                    <br />     Arbitrators, often experts in their respective fields, are neutral parties entrusted with making impartial decisions, ensuring fairness in dispute resolution.

                                    Arbitration proceedings are typically confidential, protecting the sensitive nature of many commercial disputes.

                                    Parties can tailor arbitration procedures to suit their specific needs and the complexity of the dispute, resulting in a more tailored and efficient process.

                                    Our team of Advocates is well-versed in guiding clients through the intricacies of arbitration. From crafting arbitration agreements that suit the unique requirements of each case to initiating arbitration by sending notices and progressing through the process, we provide comprehensive support to ensure a successful resolution.

                                    <br />     Mediation and out-of-court settlements offer an alternative to litigation where parties aim to resolve conflicts amicably through peaceful negotiations and agreements. Mediation is favored for its ability to provide quick, cost-effective, and conclusive solutions to disputes.
                                    Our team of Advocates encourages clients to consider mediation as a viable option, as it offers several advantages, Mediation typically involves lower costs compared to protracted legal battles.
                                    Mediation allows for swift resolution, often avoiding lengthy court proceedings.

                                    <br />     Parties have greater control over the resolution process, as they actively participate in negotiations and agreements.
                                    Mediation promotes a collaborative approach that can help maintain relationships that might otherwise be strained in a contentious legal battle.
                                    Our Advocates serve as guides, shedding light on the benefits of mediation and offering support throughout the process. We work to empower our clients to take the initiative toward mediation, which often results in the resolution of conflicts with minimal cost, time, and emotional strain.

                                    <br />     Arbitration and mediation represent two valuable paths to dispute resolution, and at Gaur Legum, we are committed to assisting clients in choosing the most suitable approach for their specific needs. When you choose Gaur Legum for your arbitration and mediation requirements, you are selecting a team of dedicated professionals who are well-versed in the intricacies of these processes. Our aim is to provide unwavering support, ensuring that your legal issues are resolved swiftly and cost-effectively while upholding your rights and interests.

                                </Fade>
                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default ArbitrationAndMediation