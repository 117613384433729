import React, {useState} from 'react'
import Navbar from '../Components/Navbar'
import Layout from '../Components/Layout'
import Footer from '../Components/Footer/Footer'
import Dropdown from '../Components/Dropdown'
import '../Style/style.css'

const Routers = () => {

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
    {/* <Header/> */}
    <Navbar toggle={toggle}/> 
    <Dropdown isOpen={isOpen} toggle={toggle} />   
    <Layout/>
    <Footer/>
    </>
  )
}

export default Routers