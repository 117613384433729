import React from 'react'
import ServiceData from '../../Data/ServiceData'
import { Container } from 'reactstrap'
import './service.css'
import { Link } from 'react-router-dom'
const Serviceitems = () => {
  return (
    <section className='service' style={{ paddingBottom: "1rem" }}>
      <h1 style={{ marginBottom: "1rem", fontSize: '1.5rem' , paddingTop:"1rem"}}>What we do </h1>
      <Container className='container '>
        <div className='struc_service'>
          <ServiceData />
        </div>
        <div className='d-flex align-items-center justify-content-center'>
          {/* <Link to="">Read More</Link> */}
          <h3 className="greater_than" style={{paddingTop:"2rem" , fontSize: "16px", margin: "0", textAlign: "end", color: "gold" }}><Link to="/practicearea"  style={{ color: "gold" }}>Learn More </Link></h3>

        </div>
      </Container>
      {/* <h5 className='learnmore greater_than'>Learn More</h5> */}
    </section>
  )
}

export default Serviceitems