import React from 'react'
import {Routes, Route} from 'react-router-dom'
import Home from '../Pages/Home';
import About from '../Pages/About';
import Contact from '../Components/Contact/index'
import Practicearea from '../Pages/Practicearea';
import Constitutional from './About/Constitutional';
import FamilyLaw from './About/FamilyLaw'
import ArbitrationAndMediation from './About/ArbitrationAndMediation'
import IntellectualPropertyRights from './About/IntellectualPropertyRights'
import MedicalNegligence from './About/MedicalNegligence'
import TechnologyAndTelecommunications from './About/TechnologyAndTelecommunications'
import TourismAndHospitality from './About/TourismAndHospitality'
import CorporateAffairsAndForeign from './About/CorporateAffairsAndForeign';
import CriminalLaw from './About/CriminalLaw';
import EmploymentAndServiceMatters from './About/EmploymentAndServiceMatters';
import ImmigrationLaw from './About/ImmigrationLaw';
import IntercountryLaws from './About/IntercountryLaws';
import LandAndPropertyDisputes from './About/LandAndPropertyDisputes';
// import Blog from './Home/Blog/Blog';
import Peoples from './Team/Peoples';
import Ajaygaur from './Team/peoples/Ajaygaur';
import Aadhaar from './Team/peoples/Aadhargaur';
// import Divya from './Team/peoples/Divya';
import Bkroy from './Team/peoples/Bkroy';
// import Jyoti from './Team/peoples/Jyoti';
import Kuber from './Team/peoples/Kuber';
import Omprakash from './Team/peoples/Omprakash';
import Raujas from './Team/peoples/Raujas';
import Consumerlaw from './About/Consumerlaw';
import MediaandSports from './About/MediaandSports';
import BankingandFinance from './About/BankingandFinance';

import { Provider } from 'react-redux';
import { store } from '../Components/Blogs/client/redux/store'
import Blogs from './Blogs/client/pages/Blogs';
import UserBlogs from './Blogs/client/pages/UserBlogs';
import BlogEdit from './Blogs/client/pages/BlogEdit';
import BlogDetails from './Blogs/client/pages/BlogDetails';
import CreateBlog from './Blogs/client/pages/CreateBlog';
import Login from './Blogs/client/pages/Login';
import Register from './Blogs/client/pages/Register';



const Layout = () => {
  return (
    <>
    <Provider store = {store}>
      <Routes>
      <Route path="/admin" element={<Blogs />} />
        <Route path ="/blog-details/:id" element={<BlogDetails/>}/>
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/my-blogs" element={<UserBlogs />} />
        <Route path="/blog-edit/:id" element={<BlogEdit />} />
        <Route path="/create-blog" element={<CreateBlog />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </Provider>
    <Routes>
        {/* <Route path ='/' element = {<Navigate to = '/home'/>}/> */}
        <Route index path = '/' element = {<Home/>}/>
        <Route path = '/about' element = {<About/>}/>
        <Route path='/contact' element = {<Contact/>}/>
        <Route path ='/practicearea' element = {<Practicearea/>}/>
        <Route path ='/constitutional' element = {<Constitutional/>}/>
        <Route path ='/peoples' element = {<Peoples/>}/>
        <Route path ='/ajaygaur' element = {<Ajaygaur/>}/>
        <Route path ='/aadhaargaur' element = {<Aadhaar/>}/>
        {/* <Route path ='/divya' element = {<Divya/>}/> */}
        <Route path ='/bkroy' element = {<Bkroy/>}/>
        {/* <Route path ='/jyoti' element = {<Jyoti/>}/> */}
        <Route path ='/kuber' element = {<Kuber/>}/>
        <Route path ='/omprakash' element = {<Omprakash/>}/>
        <Route path ='/raujas' element = {<Raujas/>}/>
        <Route path ='/familylaw' element ={<FamilyLaw/>}/>
        <Route path ='/arbitrationandmediation' element ={<ArbitrationAndMediation/>}/>
        <Route path='/intellectualpropertyrights' element={<IntellectualPropertyRights/>}/>
        <Route path='/medicalnegligence' element={<MedicalNegligence/>}/>
        <Route path='/technologyandtelecommunications' element={<TechnologyAndTelecommunications/>}/>
        <Route path='/tourismandhospitality' element={<TourismAndHospitality/>}/>
        <Route path='/bankingandfinance' element={<BankingandFinance/>}/>
        <Route path='/corporateaffairsandforeign' element={<CorporateAffairsAndForeign/>}/>
        <Route path='/criminallaw' element={<CriminalLaw/>}/>
        <Route path='/employmentandservicematters' element={<EmploymentAndServiceMatters/>}/>
        <Route path='/immigrationlaw' element={<ImmigrationLaw/>}/>
        <Route path='/intercountrylaws' element={<IntercountryLaws/>}/>
        <Route path='/landandpropertydisputes' element={<LandAndPropertyDisputes/>}/>
        <Route path='/consumerlaw' element={<Consumerlaw/>}/>
        <Route path='/mediaandsports' element={<MediaandSports/>}/>
    </Routes>
    </>
  )
}

export default Layout