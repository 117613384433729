import React from 'react'
import '../../assets/css/style.css'
import '../Slider/slider.css'
import img from '../../Images/practice_area/medical_1.jpg'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const MedicalNegligence = () => {
    return (
        <>
            <section className='slider-container_prac' >
                <div className="">
                    <Zoom>
                    <img loading="lazy" src={img} alt="" />
                    </Zoom>
                </div>
            </section>
            
            <section id="about-top" className="py100  about_us_bg">
                <div className="container" >
                    <div className="row">

                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600" }}>
                                Medical Negligence
                            </h1>
                            <p style={{ padding: "2rem" }}>
                                <Fade duration={3000}>

                                    At Gaur Legum, our dedicated team is committed to providing essential legal support to individuals and families who have suffered the profound impact of medical malpractice. We understand that medical errors can have far-reaching consequences, and our experienced team of advocates is here to offer compassionate and effective legal representation in times of distress.
                              <br/>      Our expertise in medical negligence cases spans a wide spectrum of issues. We are well-versed in handling cases involving, we recognize the devastating effects of a misdiagnosis, which can lead to delayed or incorrect treatment. Our team works diligently to ensure that patients receive the proper diagnosis and the justice they deserve.
                                    Surgical errors can be life-altering. Our advocates are equipped to investigate and hold accountable those responsible for surgical mistakes, providing our clients with the legal support they need to move forward.
                                    Medication errors can lead to severe consequences. Our team is experienced in addressing these issues and seeking justice for patients affected by prescription and administration errors.
                                    <br/> We understand the anguish caused by birth injuries, and we're dedicated to supporting families through the legal process to ensure that those responsible are held accountable.
                                    Patients have a right to expect a reasonable standard of care from healthcare providers. When this standard is not met, we step in to advocate for patients and their families.
                                    Our unwavering commitment to seeking justice for our clients sets us apart. We understand the physical, emotional, and financial toll that medical malpractice can take on individuals and their loved ones. Our mission is to ensure that victims of medical malpractice receive the justice and compensation they rightfully deserve. We are here to guide you through the legal process and help you rebuild your life after the harm you've endured.
                                    <br/> At Gaur Legum, we prioritize your well-being above all else. We recognize the importance of not only seeking legal remedies but also providing a support system for those affected by medical negligence. Our compassionate approach ensures that you are not alone in your journey to recovery. We're here to fight for your rights and to make a positive difference in your life.
                                    Medical malpractice cases require more than just legal expertise; they demand a deep sense of empathy, compassion, and an unwavering commitment to justice. When you choose Gaur Legum for your medical malpractice needs, you are choosing a team that is not only well-versed in the intricacies of medical negligence cases but is also deeply dedicated to supporting you through the healing process. Your well-being is our priority, and we are here to stand by your side, fight for your rights, and help you move toward a brighter, more secure future.

                                    {/* Our team is dedicated to helping individuals who have suffered harm due to medical malpractice. We understand the profound impact that medical errors can have on patients and their families, and our experienced team of advocates is here to provide compassionate and effective legal representation. Our expertise in medical negligence cases covers a wide spectrum of issues, including misdiagnosis, surgical errors, medication mistakes, birth injuries, and inadequate standard of care. We work tirelessly to ensure that victims of medical malpractice receive the justice and compensation they deserve. Our commitment to seeking justice for our clients sets us apart, and we aim to make a positive difference in the lives of those affected by medical negligence. Your well-being is our priority, and we're here to fight for your rights. */}
                                </Fade>
                            </p>
                        </div>
                      
                    </div>
                </div>
            </section>
        </>
    )
}

export default MedicalNegligence