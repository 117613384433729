
import React from 'react'
import ContactForm from './Contactform'
import img1 from '../../Images/icons/add.png';
import img2 from '../../Images/icons/email.png';
import img3 from '../../Images/icons/phone.png';
import location from '../../Images/icons/pin.png'
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';


const ContactDetails = () => {
    return (
        <>
            <section id="contact" className='contact' >
                <div className='contact_intro_sec' >
                    <>
                    <h1 style={{color:"gold"}}> Get in touch with us!</h1>
                    </>
                </div>
                <div className='contact_struct'>
                    <div className='col-lg-4 contact_details' style={{overflow:"hidden"}}>
                        <Fade left >
                         <div className='contact_box'>
                            <div>
                                <div className='icons_contact'>
                                    <img src={img1} loading='lazy' alt='address' />
                                    {/* <h1>Address</h1> */}
                                </div>
                                <address style={{ textAlign: "center", marginLeft: '3rem' }}>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <img src={location} loading='lazy' alt="location" style={{ height: "25px", width: "25px" }} />
                                        <p style={{padding:"0 20px 0 0"}}>20-A 2nd Floor,
                                            Sanjay Nagar, <br />
                                            Gulabi Bagh , Delhi-110007
                                        </p>
                                    </div>
                                </address>
                                <address style={{ textAlign: "center", marginLeft: "3rem" }}>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={location}loading='lazy'  alt="location" style={{ height: "25px", width: "25px" }} />
                                        <p style={{padding:"0"}}>Chamber no 234,
                                            Western Wing,
                                            <br />
                                            Tis Hazari, Delhi - 110054
                                        </p>
                                    </div>
                                </address>

                            </div>
                            <div>
                                <div className='icons_contact'>
                                <Link to='mailto:info@gaurlegum.com' target='_blank' >
                                    <img src={img2} loading='lazy' alt='Email' />
                                </Link>    {/* <h1>Phone Number</h1> */}
                                </div>
                                <p style={{ textAlign: "center" }}>
                                <Link to='mailto:info@gaurlegum.com' target='_blank' style={{textDecoration:"none", color:"white"}}  >
                                    info@gaurlegum.com
                                </Link>    {/* <h1>Phone Number</h1> */}
                                </p></div>
                            <div>
                                <div className='icons_contact'>
                                    <Link to='tel:+919810122016' target='_blank'  >
                                    <img src={img3} loading='lazy' alt='Phone Number' />
                                    </Link>
                                    {/* <h1>Email</h1> */}
                                </div>
                                <p style={{ textAlign: "center" }} >
                                    <Link to='tel:+919810122016' target='_blank'  style={{textDecoration:"none", color:"white"}}>
                                    +919810122016
                                    </Link>
                                </p>
                            </div>
                        </div>
                        </Fade>
                       
                    </div>
                    <div className='col-lg-8 d-block' style={{overflow:"hidden"}}>
                       <Fade right>
                       
                        <div className=" contact-margin">
                            <div className='container'>
                                <div className="">
                                    <h4 style={{ fontSize: "20px" }}>
                                        If you got any questions <br />
                                        please do not hesitate to send us a message.
                                    </h4>
                                    <div className="contact-margin ">
                                        <ContactForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Fade>
                    </div>
                </div>
                <div className='map'>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.4980194884306!2d77.19006907608976!3d28.6747454821614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03998454febb%3A0xbe4b67f72945c49d!2sGaur%20Legum!5e0!3m2!1sen!2sin!4v1698686977215!5m2!1sen!2sin"
                        title='map'
                        height="300"
                        loading='lazy'>
                    </iframe>
                </div>
                <div />
            </section>
        </>
    )
}

export default ContactDetails
