import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Button, Grid, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";

export default function BlogCard({
  title,
  description,
  image,
  username,
  time,
  id,
  isUser,
}) {
  const navigate = useNavigate();
  const handleDetail = () => {
    navigate(`/blog-details/${id}`)
  };
  const handleEdit = () => {
    navigate(`/blog-edit/${id}`);
  };

  const handleDelete = async () => {
    try {
      const { data } = await axios.delete(`/api/v1/blog/delete-blog/${id}`);
      if (data?.success) {
        alert("Blog Deleted");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addEllipsis = (str, limit) => {
    return str.length > limit ? str.substring(0, limit) + '...' : str;
  };
  return (
    <Card
    className="blog_card"
      // sx={{
      //   width: "20%",
      //   margin: "auto",
      //   mt: 2,
      //   padding: 2,
      //   boxShadow: "5px 5px 10px #ccc",
      //   transition: "all .5s  ease",
      //   ":hover:": {
      //     transform: "translateY(-10px)",
      //     boxShadow: "10px 10px 20px #ccc",
      //   },
      // }}
    >
      {isUser && (
        <Box display={"flex"}>
          <IconButton onClick={handleEdit} sx={{ marginLeft: "auto" }}>
            <ModeEditIcon color="info" />
          </IconButton>
          <IconButton onClick={handleDelete}>
            <DeleteIcon color="error" />
          </IconButton>
        </Box>
      )}

      <CardMedia component="img" height="194" image={image} alt="Paella dish" />
      <CardContent>
        <Typography textAlign="Left" gutterBottom variant="h5" color="text.secondary" component="div">
          {addEllipsis(title, 20)}
        </Typography>
        <Typography textAlign="left" variant="body3" color="text.secondary">
          {addEllipsis(description, 100)}
        </Typography>
        <br/>
        <br/>
        <Button onClick={handleDetail}>
          Read More...
        </Button>
      </CardContent>
    </Card>
  );
}
