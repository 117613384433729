import React from 'react'
import '../../assets/css/style.css'
import Fade from 'react-reveal/Fade';

const History = () => {
    return (
        <>
            <section id="about-top" className="py100">
                <div className="container" >
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <h2 style={{ textAlign: 'center', fontWeight: "600", position: 'relative' }}>
                                About Us
                            </h2>

                                <Fade duration={3000}>
                            <p style={{ padding: "2rem", textAlign: 'left' }}>

                                    Gaur Legum, an acclaimed law firm in the world offers a full spectrum of legal services to clients spanning domestic and international domains on a variety of legal matters. Its inception traces back to 1988 when it was established in New Delhi, the Capital of India.
                                    <br />
                                    <br />
                                    Since its inception, the firm has served a wide range of clients, including banks, government organizations, corporations, multinational companies, public sector undertakings, private companies, enterprises, institutions, and individuals. The firm offers and provides efficient and result-oriented legal services to domestic and international clients. The law practice was founded with the intention of effectively ameliorating people and society in overcoming any legal challenges for its clients. Building relationships, providing exceptional service, and understanding our clients to the core is our precept. It is now regarded as the symbol of openness and trust.
                                    <br />
                                    <br />
                                    Currently, the firm is based in Delhi-NCR and has associates in nearly all of India's significant cities. By offering our customers the best possible legal counsel, the law firm has expanded swiftly. By raising awareness and aiding clients with representation in various local and international disputes and issues, our focus is on the welfare of individuals and society.
                                    <br />
                                    <br />
                                    Our associates have handled cases all around the nation. We have achieved this by forging strong bonds with a network of essential associates, who play a substantial role in assisting and completing the tasks for our clients.
                                    <br />
                                    <br />
                                    But what differentiates us is the way we work with our clients. Every action we take on behalf of our clients is done in the context of a long-term partnership. The intelligent advancement of our client's overall business objectives is always our main priority. Even while it is crucial, what we are currently doing for a client is merely one part of a long-term collaboration.
                                    <br />
                                    <br />
                                    We work hard to make sure that our interactions with our clients are positive right away. However, as the connection develops, we want our clients to start seeing us as an extension of their company rather than just an external service provider.
                            </p>
                                </Fade>
                        </div>
                      
                    </div>
                </div>
            </section>
        </>
    )
}

export default History