import React from 'react'
import '../../../assets/css/style.css'
import '../../Slider/slider.css'
import img from '../../../Images/people/aadhaargaur.png'
import Fade from 'react-reveal/Fade'
const Aadhaar = () => {
    return (
        <>
        <section className='slider-container' >
            <div className="">
                <Fade duration={1000}>
                <img className='slide-image' loading="lazy" src={img} alt="" />
                </Fade>
            </div>
        </section>
            <section id="about-top" className="py100  about_us_bg" style={{ background: "#143d53", color: "#f0f0f0" }}>
                <div className="container" >
                    <div className="row">

                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600", color: "gold", textTransform: "uppercase", fontSize:'2rem'}}>
                                Aadhaar Gaur
                            </h1>
                            <h4 style={{ textAlign: 'center', fontWeight: "500", color: "gold" }}>
                            Marketing Head and Associate
                            </h4>
                            <p >
                                <Fade bottom>
                            Legal challenges can be daunting, but with the right guidance and advocacy, we can overcome them together.
                                <br />  Mr. Aadhaar Gaur, holds a distinguished 5-year integrated BALLB(H) degree, graduating in the first division with distinction from GGSIP University, bringing a strong academic foundation to his practice. In 2022, he successfully got himself enrolled in the the Bar Council of Delhi, marking a significant milestone in his legal career. He has extensive expertise in the intricate realms of both Criminal Law and Constitutional Law. In the field of Criminal Law, his knowledge spans a wide array of offenses, ranging from petty offences to serious crimes such as murder, encompassing everything from property crimes to violent offences.
                                <br />  Furthermore, his specialisation in Constitutional Law delves into the fundamental principles that govern our legal system. This encompasses a thorough grasp of fundamental constitutional rights, pivotal court rulings that have shaped legal precedents, and the delicate equilibrium in power distribution among different branches of the government. An ardent advocate of the principles of our Indian Constitution which includes Equity, Justice, Fairness and Reasonableness
                                <br />  He is devoted to supporting others and raising awareness about the profound impact their present choices can wield on their future lives.
                                <br />  He emerges as an individual who is outgoing, approachable, and sociable, displaying qualities of empathy, modesty, and a cheerful disposition. This combination of traits allows him to connect with people effectively and create positive and meaningful interactions.
                                </Fade>
                             </p>
                        </div>
                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-top-img animation-img-two">
                    <img alt="" src={img1} />
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-img">
                    <h2>Our Vision</h2>
                    {/* <p>
            To be the most preferred Infrastructure and Solutions Provider 
            </p>  <h5>Spreading happiness through the Sound and Visual senses.</h5> 
                    <p>
                        Through:
                    </p>

                    <ul>
                        <li><i><FiCheck /></i>Professional Audio Systems Solution</li>
                        <li><i><FiCheck /></i>Professional Video Systems Solution</li>
                        <li><i><FiCheck /></i>Professional Lighting Systems Solution</li>
                        <li><i><FiCheck /></i>Broadcast Systems Solution</li>
                        <li><i><FiCheck /></i>Acoustic Design & Consultancy</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="row" style={{ marginTop: "1rem" }} >
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-img">
                    <h2>Our Evolution</h2>
                    <p>
                        TCI ZEN is a Pro-Audio, Video, Broadcast, Lighting & Acoustics Design and Consultancy.
                        Since its inception, TCI ZEN have come a long way. From a 'me too' company TCI ZEN has been
                        able to create a niche for itself in the fiercely competitive environment.
                    </p>
                    <p>This has
                        been made possible by incorporating our vision of providing solutions to our business
                        partners to maximize the business benefits by enabling technology.</p>
                    <p>
                        The company formed and managed by professionals who are dreamers,
                        and who dream the most do the most, with the required expertise and know-how.
                        All this with an uncanny ability to sense the future potential.
                    </p>
                    <p>Strong technical
                        expertise is blended with hard-core marketing principles to cultivate a whole new
                        set of ethos, work culture and technological break through. As a result, a better
                        and brighter support to our clients.</p>

                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-top-img animation-img-two">
                    <img alt="" src={img2} />
                </div>
            </div>
        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aadhaar
