import React from 'react'
import '../../assets/css/style.css'
import '../Slider/slider.css'
import img from '../../Images/practice_area/criminal.png'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const CriminalLaw = () => {
    return (
        <>
            <section className='slider-container_prac' >
                <div className="">
                    <Zoom>
                    <img loading="lazy" src={img} alt="" />
                    </Zoom>
                </div>
            </section>
            
            <section id="about-top" className="py100  about_us_bg">
                <div className="container" >
                    <div className="row">

                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600" }}>
                                Criminal Law
                            </h1>
                            <p style={{ padding: "2rem" }}>

                                <Fade duration={3000}>

                                    At Gaur Legum, our resolute team of advocates specializes in criminal litigation, offering ethical and empathetic legal services to ensure that our clients receive diligent and dependable representation. With experience in the field, our advocates are well-equipped to oversee complex criminal cases, always prioritizing a straightforward and fair approach while upholding the highest standards of client-advocate ethics.
                                    <br />
                                    <br />       An advocate’s commitment to ethical and empathetic dealings is at the core of our practice area. We understand the stress and uncertainty that individuals facing criminal charges experience. Therefore, our advocates take a compassionate approach, providing clients with the support and understanding they need during this challenging time. We prioritize open communication, ensuring that clients are well informed about the legal process and their options.
                                    <br />
                                    <br />     Criminal cases often involve intricate legal issues and intricate facts. Our experienced advocates excel at interpreting difficult criminal cases, dissecting the complexities, and developing effective legal strategies. We ensure that our clients’ rights are protected and that their interests are advanced with the utmost competence and dedication.
                                    Maintaining the highest standards of client-advocate ethics is non-negotiable at Gaur Legum. We are committed to building trust with our clients by upholding honesty, confidentiality, and transparency. Our clients can rely on us to act in their best interests, and we go to great lengths to ensure their confidence in our legal services.
                                    <br />
                                    <br />     Our associates are dedicated to seeking the best legal remedies for our clients. We understand that criminal lawsuits demand extensive research, in-depth legal analysis, and swift drafting of legal documents. Our team's proficiency in these areas allows us to deliver effective legal solutions promptly, ensuring that our clients’ rights are safeguarded, and their cases are advanced efficiently.
                                    <br />
                                    <br />     Every month, we serve a substantial number of litigants who rely on us to navigate the complex terrain of criminal litigation. Our advocates are known for their unwavering commitment to restoring litigants' trust and faith in the law and the legal system. We achieve this through providing correct counsel, competent aid, and effective legal recourses, thereby demonstrating that justice can be served fairly and efficiently.
                                    At Gaur Legum, our Criminal Litigation practice is more than just a service; it is a commitment to ethical representation, prompt justice, and the restoration of trust in the legal system. We are dedicated to standing by our clients, ensuring they receive the highest level of support and advocacy throughout their legal journey.

                                       </Fade>
                            </p>
                        </div>
                      
                    </div>
                </div>
            </section>
        </>
    )
}

export default CriminalLaw 