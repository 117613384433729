import React from 'react'
// import img from  '../../../Images/team/OurTem/bk roy.jpg'
import img from '../../../Images/people/bkroy.png'
import Fade from 'react-reveal/Fade'
const Bkroy = () => {
    return (
        <>
            <section className='slider-container' >
                <div className="slides">
                    <Fade>
                        <img className='slide-image' loading='lazy' src={img} alt="" />
                    </Fade>
                </div>
            </section>
            <section id="about-top" className="py100  about_us_bg" style={{ background: "#143d53", color: "#f0f0f0" }}>
                <div className="container" >
                    <div className="row">

                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600", color: "gold", textTransform: "uppercase", fontSize: '2rem' }}>
                                Braj Kishore Roy
                            </h1>
                            <h4 style={{ textAlign: 'center', fontWeight: "500", color: "gold" }}>
                                Senior Associate
                            </h4>
                            <p>
                                <Fade Bottom>
                                Mr. Braj Kishore Roy stands as a distinguished and dedicated advocate with an impressive track record of 19 years in the realm of civil and commercial law. His wealth of experience not only reflects his seasoned expertise but also underscores his unwavering commitment to his profession. His extensive expertise is a testament to his dedication and passion for upholding the principles of justice and serving his clients with unparalleled excellence.
                                <br /> He successfully completed his undergraduate studies, earning a BALLB degree, followed by a post-graduation degree in MBA with a specialization in Finance. In the year 2004, he took the formal step of enrolling in the Bar Council of Delhi, marking the beginning of his professional journey as an advocate. The notion that the sky's the limit has consistently remained a guiding principle in his life, emphasizing the boundless opportunities and fueling an inexhaustible well of motivation within him. This belief in limitless possibilities serves as a driving force, propelling him to pursue his goals with great determination and enthusiasm.
                                </Fade>

                            </p>
                        </div>
                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                 <div className="about-top-img animation-img-two">
                     <img alt="" src={img1} />
                 </div>
             </div>
             <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                 <div className="about-img">
                     <h2>Our Vision</h2>
                     {/* <p>
             To be the most preferred Infrastructure and Solutions Provider 
             </p>  <h5>Spreading happiness through the Sound and Visual senses.</h5> 
                     <p>
                         Through:
                     </p>

                     <ul>
                         <li><i><FiCheck /></i>Professional Audio Systems Solution</li>
                         <li><i><FiCheck /></i>Professional Video Systems Solution</li>
                         <li><i><FiCheck /></i>Professional Lighting Systems Solution</li>
                         <li><i><FiCheck /></i>Broadcast Systems Solution</li>
                         <li><i><FiCheck /></i>Acoustic Design & Consultancy</li>
                     </ul>
                 </div>
             </div>
         </div>
         <div className="row" style={{ marginTop: "1rem" }} >
             <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                 <div className="about-img">
                     <h2>Our Evolution</h2>
                     <p>
                         TCI ZEN is a Pro-Audio, Video, Broadcast, Lighting & Acoustics Design and Consultancy.
                         Since its inception, TCI ZEN have come a long way. From a 'me too' company TCI ZEN has been
                         able to create a niche for itself in the fiercely competitive environment.
                     </p>
                     <p>This has
                         been made possible by incorporating our vision of providing solutions to our business
                         partners to maximize the business benefits by enabling technology.</p>
                     <p>
                         The company formed and managed by professionals who are dreamers,
                         and who dream the most do the most, with the required expertise and know-how.
                         All this with an uncanny ability to sense the future potential.
                     </p>
                     <p>Strong technical
                         expertise is blended with hard-core marketing principles to cultivate a whole new
                         set of ethos, work culture and technological break through. As a result, a better
                         and brighter support to our clients.</p>

                 </div>
             </div>
             <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                 <div className="about-top-img animation-img-two">
                     <img alt="" src={img2} />
                 </div>
             </div>
         </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Bkroy