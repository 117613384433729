import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'


const FormInput = props => {
    
    const form = useRef();
    
    let options = props.options || []

    function handleClick(){
        if(props.id===null){
            alert('Please Fill the required Form')
        }
        else{

            alert('Thank you for Contacting Us')

            emailjs.sendForm('service_euniy1e', 'template_iq9vnsh', form.current, 'AtFtxd1aURqUnbMkG')
            .then((result) => {
                console.log(result.status);
                }, (error) => {
                    console.log(error.text);
                });
    
        }
    }

    return (
        <>
            <div className="form-group">
                {props.label && (<label>{props.label}</label>)}
                {props.tag === 'input' && (
                    <input type={props.type} name={props.name} id={props.id} placeholder={props.placeholder}
                           className={props.classes} required/>
                )}
                {props.tag === 'number' && (
                    <input type={props.type} name={props.name} id={props.id} placeholder={props.placeholder}
                           className={props.classes} required/>
                )}
                {props.tag === 'textarea' && (
                    <textarea name={props.name} cols="30" rows="7" id={props.id} placeholder={props.placeholder}
                              className={props.classes} required/>
                )}
                {props.tag === 'password' && (
                    <input type={props.type} name={props.name}  id={props.id} placeholder={props.placeholder}
                           className={props.classes} required/>
                )}
                {props.tag === 'button' && (
                    <button className={`btn btn-theme`} onClick={handleClick} >{props.val}</button>
                )}
                {props.tag === 'select' && (
                    <select className="form-control first_null" id="city">
                        {options.map((data, index) => (
                            <option key={index} value={data.value}>{data.text}</option>
                        ))}
                    </select>
                )}
            </div>
        </>
    )
}

export default FormInput

