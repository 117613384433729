import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom';
import { menuData } from '../Data/MenuData';
import { Button } from './Button';
import Logo1 from '../Images/logo.png'

const Nav = styled.nav`
height: 90px;
display: flex;
justify-content: space-between;
padding: 1rem 2rem;
z-index: 1000;
position: fixed;
width: 100%;
`;

const NavLink = css`
color: #fff;
display: flex;
align-items: center;
padding: 0 1rem;
height: 100%;
cursor: pointer;
text-decoration: none;
@media screen and (max-width: 850px){
    padding: 0 .4rem;
}
`

const Logo = styled(Link)`
${NavLink}
position:relative;
width:65px;
height:70px;
top:20px;
left:20px;
@media all and (max-width: 1080px){
top:25px;
        width: 55px !important;
        height: 55px !important; 
}
        @media all and (max-width: 580px){
top:20px;
        width: 45px !important;
        height: 45px !important; 
}
`;

const MenuBars = styled.div`
display: none;
@media screen and (max-width: 850px){
    display: block;
    height: 40px;
    width: 40px;
    color:white
    cursor: pointer;
    position: relative;
    right: 0;
    left:20px;
    top:5px;
}
`;
const NavMenu = styled.div`
display: flex;
align-items: center;
margin-right: -48px;
@media screen and (max-width: 850px) {
    display: none;
}
`;

const NavMenuLinks = styled(Link)`
${NavLink}
font-size:14px;
padding:0 30px;
text-transform: uppercase;
`;

const Navbtn = styled.div`
display: flex;
align-items: center;
margin-right: 24px;
@media screen and (max-width: 850px) {
    display: none;
    font-size: 1rem;
}
`


const Navbar = ({ toggle }) => {
    const [isActive, setIsActive] = useState(false);

    const handleClick = event => {
        setIsActive(current => !current);
    };
    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        }
        else {
            setColorchange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);
    return (
        <>
            <Nav className={colorChange ? 'navbar colorChange' : 'navbar'}>
                <Logo className="navbar-brand" to='/' >

                    <img loading='lazy' src={Logo1} alt="Logo" className='logo' />
                </Logo>
                <MenuBars className='toggle_nav' onClick={toggle} >
                    <div className={isActive ? 'animate_btn' : ''} onClick={handleClick} >
                        <div className='line'></div>
                        <div className='line'></div>
                        <div className='line'></div>
                    </div>
                </MenuBars>
                <NavMenu>
                    {
                        menuData.map((item, index) => (
                            <NavMenuLinks to={item.link} key={index} className="hov_nav">
                                {item.title}
                            </NavMenuLinks>
                        ))
                    }
                </NavMenu>
                <Navbtn>
                    <Button to="/contact" primary='true' > Contact Us</Button>
                </Navbtn>
            </Nav>
        </>
    )
}

export default Navbar