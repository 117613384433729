import React from 'react'
import '../../assets/css/style.css'
import '../Slider/slider.css'
import img from '../../Images/practice_area/banking.png'
import Fade from 'react-reveal/Fade';

import Zoom from 'react-reveal/Zoom'
const BankingandFinance = () => {
    return (
        <>
            <section className='slider-container_prac' >
                <div className="">
                    <Zoom>
                    <img loading="lazy" src={img} alt="" />
                    </Zoom>
                </div>
            </section>
           
            <section id="about-top" className="py100  about_us_bg">
                <div className="container" >
                    <div className="row">

                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600" }}>
                                Banking and Finance Law
                            </h1>
                            <p style={{ padding: "2rem" }}>


                                <Fade duration={3000}>
                                    At Gaur Legum, our Banking and Finance practice area is a powerhouse of expertise, dedicated to providing innovative, ethical, and legally sound financial solutions to a diverse clientele. Our team of dedicated Associates excels in handling a wide array of financial transactions, including domestic loans, external commercial borrowing, acquisition finance, project finance, structured finance, and re-financing transactions.
                                    <br />
                                    <br />
                                    In today's dynamic financial landscape, navigating the complexities of the banking and finance industry can be challenging. However, at Gaur Legum, our Associates are well-versed in comprehending and navigating the ever-evolving rules and laws that apply to the banking sector. This deep understanding is pivotal in ensuring that our clients receive cutting-edge, original, and legally compliant financial solutions. We pride ourselves on staying ahead of the curve in terms of industry knowledge and legal developments.
                                    <br />
                                    <br />
                                    We recognize that the banking and finance sector serves a diverse range of stakeholders, from financial institutions and corporations to individual borrowers and investors. At Gaur Legum, we are committed to meeting the unique needs of each client. Our team is adept at providing advice on distressed debt circumstances, ensuring that clients facing financial challenges receive expert guidance and support.
                                    <br />
                                    <br />
                                    Our capabilities in the banking and finance sector specialization go beyond mere legal expertise. We have established solid relationships with numerous authorities and important players in the banking industry. This unique perspective enables us to offer practical and commercially viable legal advice that not only safeguards our clients' interests but also helps them capitalize on opportunities within the financial sector.
                                    <br />
                                    <br />
                                    In an industry where financial stability and profitability are paramount, Gaur Legum excels in delivering commercially viable solutions. Whether it's structuring complex financial deals or assisting clients in making strategic financial decisions, we always prioritize the best interests of our clients. Our goal is to help them achieve their financial objectives while staying within the bounds of the law.
                                    <br />
                                    <br />
                                    The Banking and Finance Law at Gaur Legum stands as a beacon of trust, competence, and innovation in the financial world. We offer a comprehensive range of services, from traditional lending to cutting-edge financial solutions, all delivered with the utmost commitment to ethical and legal standards. By choosing Gaur Legum for your banking and finance needs, you are choosing a team that is ready to provide you with top-tier legal support and financial advice, tailored to your unique requirements and driven by a deep understanding of the industry's intricacies.

                                      </Fade>
                            </p>
                        </div>
                       
                    </div>
                </div>
            </section>
        </>
    )
}

export default BankingandFinance