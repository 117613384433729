import React, { useState } from 'react'
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { FaAward ,FaSuitcase} from "react-icons/fa";
import { GoLaw } from "react-icons/go";
import CountUp from 'react-countup';
import '../../../assets/css/style.css'
import VisibilitySensor from "react-visibility-sensor";

const Index = () => {
    const [focus, setFocus] = useState(false);
    return (
        <>
            <section id="counter-area" className="py100" style={{ background: "#0f2c3c", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                <div className="container" >
                    <div className="row center">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-4" >
                            <div className="counter-area center">
                                <div className="counter-icon">
                                    <i><AiOutlineUsergroupAdd /></i>
                                </div>
                                <div className="counter-body">
                                    <h2 className="counter">
                                        <CountUp start={focus ? 0 : null} end={4500} duration={2} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} /> 
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                        
                                 +   </h2>
                                    <h4>Cases settled</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-4">
                            <div className="counter-area center">
                                <div className="counter-icon">
                                    <i><GoLaw /></i>
                                </div>
                                <div className="counter-body">
                                    <h2 className="counter">
                                    <CountUp start={focus ? 0 : null} end={700} duration={2} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                       + </h2>
                                    <h4>Ongoing Cases</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-4">
                            <div className="counter-area center">
                                <div className="counter-icon">
                                    <i><FaAward /></i>
                                </div>
                                <div className="counter-body">
                                    <h2 className="counter">
                                    <CountUp start={focus ? 0 : null} end={5000} duration={2} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                       + </h2>
                                    <h4>Credible clients</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-4">
                            <div className=''>
                                <div className="center counter-area ">
                                    <div className="counter-icon">
                                        <i><FaSuitcase /></i>
                                    </div>
                                    <div className="counter-body">
                                        <h2 className="counter">
                                        <CountUp start={focus ? 0 : null} end={15000} duration={2} redraw={true}>
                                            {({ countUpRef }) => (
                                                <VisibilitySensor onChange={(isVisible) => {
                                                    if (isVisible) { setFocus(true); }
                                                }}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                            +
                                           </h2>
                                        <h4 style={{ textAlign: "center" }}>Consultation offered</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Index
