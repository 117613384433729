import React from 'react'
import '../../assets/css/style.css'
import '../Slider/slider.css'
import img from '../../Images/practice_area/employment.png'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const EmploymentAndServiceMatters = () => {
    return (
        <>
            <section className='slider-container_prac' >
                <div className="">
                    <Zoom>
                    <img loading="lazy" src={img} alt="" />
                    </Zoom>
                </div>
            </section>
            
            <section id="about-top" className="py100  about_us_bg">
                <div className="container" >
                    <div className="row">

                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600" }}>
                                Employment And Service Matters
                            </h1>
                            <p style={{ padding: "2rem" }}>
                                <Fade duration={3000}>

                                    At Gaur Legum, our commitment to providing highly specialized legal services is exemplified through our Industrial and Service Law practice. Our team of attorneys boasts unrivaled experience in this dynamic field, and we take pride in our ability to assist clients with regulatory compliance under various labor and service laws in India. We are here to represent and guide our clients through complex legal matters pertaining to labor-management interactions, anti-discrimination laws, employment benefits, and related litigation.
                                    <br />
                                    Our Industrial and Service Law practice covers a wide spectrum of legal issues, ensuring that we can address the diverse needs of our clients. We regularly provide legal advice and representation to various institutions and corporate/private clients, including, we offer legal guidance to educational institutions on various labor and employment matters, ensuring that they operate in compliance with the law while prioritizing the rights and well-being of their employees.
                                    <br />
                                    Our expertise extends to assisting real estate developers in navigating the complexities of labor laws, ensuring that their projects are executed in compliance with all legal requirements.
                                    
                                    We provide legal support to hotel chains, addressing labor and employment issues, compliance with labor laws, and handling disputes that may arise in this dynamic industry.
                                    Major pharmaceutical companies rely on our advocacy for guidance on employment matters, labor disputes, and ensuring compliance with industry-specific regulations.
                                    We represent and advise industrial players in the healthcare sector, offering legal support on labor and employment issues that are specific to this field.
                                    Our Advocates are well-versed in representing clients in trade unions and employment-related litigation. We understand the nuances of labor-management interactions and the legal complexities involved. Whether it's resolving disputes, addressing disciplinary proceedings, or ensuring employees' rights and related benefits are upheld, we stand by our clients as dedicated legal allies.
                                    <br />
                                    In the realm of Industrial and Service Law, our primary goals are compliance with the law and fair resolution of disputes. We believe in providing our clients with the necessary legal tools and guidance to operate within the boundaries of labor and service laws, fostering harmonious and productive workplaces. When disputes arise, we work diligently to resolve them through negotiation or litigation, always with our client's best interests at heart.
                                    <br />
                                    Industrial and Service Law is a highly specialized practice at Gaur Legum, where our team's dedication and experience shine. When you choose Gaur Legum for your Industrial and Service Law needs, you are selecting a team that is not only well-versed in the intricacies of the industry but also deeply committed to your compliance, well-being, and success. We are here to provide unwavering support and guidance through the complexities of labor and service laws, ensuring that your legal needs are met with the highest level of expertise and dedication.

                                  </Fade>

                            </p>
                        </div>
                      
                    </div>
                </div>
            </section>
        </>
    )
}

export default EmploymentAndServiceMatters  