import React from 'react'
import History from '../Components/About/History'
import'../Components/Slider/slider.css'
//import GetStarted from '../Components/Common/GetStarted/index'
import '../assets/css/style.css'
import '../assets/css/responsive.css'
import img from '../Images/team/PPS_1628_1_e.png'
import Fade from 'react-reveal/Fade'

const About = () => {
  
  return (
    <>
    <section>
      <div className="slider-container">
        <div className="slides">
          <Fade duration={1000}>
          <img className='slide-image'  src={img} style={{objectPosition:'top'}} alt="" />
          </Fade>
        </div>
      </div>
      <History/>
    </section>
    </>
  )
}

export default About