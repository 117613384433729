export const menuData = [
    {
        title: 'Home',
        link: '/',
    },
    {
        title: 'About us',
        link: '/about'
    },
    {
        title: "Our People",
        link: "/peoples",
    },
    {
        title: 'Practice Areas',
        link: '/practicearea',
    },

    {
        title: "Blogs",
        link: "/blogs",
    }
]