import React from 'react'
import { Col } from 'reactstrap'

import ServiceCard from '../Components/Service/ServiceCard'

import Image1 from "../Images/icons/services/land_1.png"
import Image2 from '../Images/PracticeAreaIcons/arbitration.png'
import Image3 from '../Images/PracticeAreaIcons/family-law.png'
import Image4 from '../Images/PracticeAreaIcons/criminal.png'
import Image5 from '../Images/PracticeAreaIcons/constituional.png'
import Image6 from '../Images/PracticeAreaIcons/corporate.png'
const serviceData =[

    {
        imageUrl: Image1,
        title: "Land & Property Disputes",
        path: '/landandpropertydisputes',
    },
    {
        imageUrl: Image2,
        title: "Arbitration & Mediation ",
        path: '/arbitrationandmediation',
    },
    {
        imageUrl: Image3,
        title: "Divorce & Family Laws",
        path: '/familylaw',
    },
    {
        imageUrl: Image4,
        title: "Criminal Laws ",
        path: '/criminallaw',
    },
    {
        imageUrl: Image5,
        title: "Constitutional Law",
        path: '/constitutional',
    },
    {
        imageUrl: Image6,
        title: "Inter Country Laws",
        path: '/intercountrylaws',
    }
   ]

const ServiceData = () => {
  return (
    <>
        {
        serviceData.map((item, index) =>
        <Col key={index} className='center d-flex justify-content-center align-items-center'>
            
            <ServiceCard item = {item}/>
            </Col>)
    }
    </>
  )
}

export default ServiceData