import React from 'react'
import { CDBBtn, CDBIcon, CDBBox} from 'cdbreact';
import styled from 'styled-components';
import './footer.css'
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

const SectionFooter = styled.section`
width: 100%;
padding:1rem 0;
color: gold;
background-color: #0f2c3c;
`;

const Footer = () => {
  return (
    <SectionFooter  style={{overflow:'hidden'}}>
      <Fade bottom >
     <CDBBox display="flex" flex="column" className="mx-auto">
      <CDBBox display="flex" justifyContent="center" className="flex-wrap footer-flex">
        <CDBBox alignSelf="center" >
          <CDBBox className="mt-2" display="flex ">
            <Link style={{textDecoration:"none"}} to="https://www.facebook.com/gaurlegum/" target='_blank'>
            <CDBBtn flat color="dark" className="p-2">
              <CDBIcon fab icon="facebook-f"className='g' />
            </CDBBtn>
              </Link>           
            <Link style={{textDecoration:"none"}} to="https://wa.me/+919810122016" target='_blank'>
            <CDBBtn flat color="dark" className="mx-3  p-2">
              <CDBIcon fab icon="whatsapp" className='g' />
            </CDBBtn>
              </Link>
              <Link style={{textDecoration:"none"}} to="https://www.instagram.com/gaurlegum?igsh=MTdiaGl2c3Z0d3Y3Mw==" target='_blank'>
            <CDBBtn flat color="dark" className="p-2 ">
              <CDBIcon fab icon="instagram"  className='g'/>
            </CDBBtn>
              </Link>
              <Link style={{textDecoration:"none"}} to="https://www.linkedin.com/company/gaur-legum/" target='_blank' >
            <CDBBtn flat color="dark" className=" mx-3 p-2 ">
              <CDBIcon fab icon="linkedin"  className='g'/>
            </CDBBtn>
              </Link>
          </CDBBox>
        </CDBBox>
     
      
        {/* <CDBBox>
          <p className="h5 mb-4" style={{ fontWeight: '600', textAlign: 'left' }}>
            Contact us
          </p>
          <CDBBox marginLeft = '1rem' display="flex" flex="column" style={{ cursor: 'pointer', textAlign: 'left' }}>
            <CDBNavLink path="/"><i class="ri-phone-fill"></i>Phone</CDBNavLink>
            <CDBNavLink path="/">Email</CDBNavLink>
            <CDBNavLink path="/">Address</CDBNavLink>
          </CDBBox>
        </CDBBox> */}
      </CDBBox>
      <small className="text-center mt-2">&copy; Gaur Legum, 2023 - 2024. All rights reserved.</small>
      {/* <small className="text-center mb-5">Developed by: <Link style={{textDecoration:"none"}} to="https://linkedin.com/in/deveshjain0304"> Devesh Jain</Link> </small> */}
    </CDBBox>
    </Fade>
    
  </SectionFooter>
  )
}

export default Footer