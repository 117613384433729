import React from "react";
import Serviceitems from "../Components/Service/Serviceitems";
import Contact from "../Components/Contact/index";
import Counter from "../Components/Common/Counter";
import img1 from "../Images/team/OurTem/Ajay_gaur.webp";
import { Link } from "react-router-dom";
import Slider from "../Components/Slider/Slider";
import Testimonialcards from "../Components/Testi/Testimonial_cards";

const Home = () => {
  return (
    <div>
      <Slider id="home" />
      <section id="about-top" className="py100 center " style={{
        background: "#143d53"
      }}>
        <div className="found_msg_head " style={{
          border: "2px solid white", height: 'auto', margin: "0 10px"
        }}>
          <div className="row">
            <div className="col-lg-6 col-md-12 d-flex" >
              <div className="about-img">
                <h3 style={{ fontWeight: "800", textAlign: 'left', paddingTop: "20px" }}>Welcome to Gaur Legum</h3>
                <hr className="hrsytle" />
                <p>
                  I, Ajay Gaur and my colleagues at Gaur Legum are deeply passionate about our profession, fostering a strong team spirit and an unwavering dedication to our clients' interests. Our minds are constantly engaged, even subconsciously, reflecting our relentless commitment to achieving the best outcomes. In the realm of advocacy, professionals like us continuously contemplate and analyze diverse matters, striving for optimal results. The legal field, akin to a system of dedicated individuals, works collaboratively to attain outcomes that benefit everyone. This profession attracts individuals from diverse backgrounds, each contributing their unique attributes and expertise, making it a vibrant and dynamic arena.
                  <br />
                  Our firm's unique expertise provides assurance and contentment to our clients. Through teamwork and support, we aspire to achieve even greater accomplishments in the future.
                </p>
                <h3 style={{ fontSize: "24px", marginTop: "1rem", fontWeight: "600", color: "goldenrod" }}>Ajay Gaur</h3>
                <h3 style={{ fontSize: "18px", marginBottom: "10" }}>Founder</h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12 mt-4 center d-flex justify-content-center align-items-center">
              <div className="about-top-img">
                <img alt="" style={{ borderRadius: '10px' }} src={img1} />
                {/* <div className="shadow_gold"/> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12 mt-2" >
              <div className="about-img">
                <h3 style={{ fontWeight: "600", textAlign: 'left', paddingTop: "10px" }}>Who are we ?</h3>
                <hr className="hrstyles" />
                <p style={{ textAlign: 'left' }}>
                  Gaur Legum, an acclaimed law firm in the world offers a full spectrum of legal services to clients spanning domestic and international domains on a variety of legal matters. Its inception traces back to 1988 when it was established in New Delhi, the Capital of India.
                  <br /><br />
                  Currently, the firm is based in Delhi-NCR and has associates in nearly all of India's significant...
                </p>
                {/* <h3 style={{ fontSize: "24px", textAlign: "end", marginTop: "1rem", fontWeight: "600", color: "goldenrod" }}> </h3> */}
                <h3 className="greater_than" style={{ fontSize: "16px", margin: "0", textAlign: "end", color: "gold" }}><Link to="/about" style={{ color: "gold" }}>Read More </Link></h3>
              </div>
            </div>
          </div>
        </div>
      </section>     
      <Counter />
      <Serviceitems />
      <Testimonialcards />
      <Contact />
    </div>
  );
};

export default Home;
