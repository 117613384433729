import React from 'react'
import '../../assets/css/style.css'
import '../Slider/slider.css'
import img from '../../Images/practice_area/media.jpg'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const MediaandSports = () => {
     return (

          <>
               <section className='slider-container_prac' >
                    <div className="">
                         <Zoom>
                         <img loading="lazy" src={img} alt="" />
                         </Zoom>
                    </div>
               </section>
               {/* <section id='banner-inner-area1'>
                    <div className="container">
                         <div className="row">
                              <div className="col-lg-12">
                                   <div className="banner-details text-center">
                                   </div>
                              </div>
                         </div>
                    </div>
               </section> */}
               <section id="about-top" className="py100  about_us_bg">
                    <div className="container" >
                         <div className="row">

                              <div className="col-md-12 col-sm-12 col-12">
                                   <h1 style={{ textAlign: 'center', fontWeight: "600" }}>
                                        Media & Sports Laws
                                   </h1>
                                   <p style={{ padding: "2rem" }}>
                                        <Fade duration={3000}>
                                             Gaur Legum proudly offers an all-encompassing and dynamic Media and Sports Law practice that caters to the distinctive legal needs of individuals and entities within the sports and entertainment industry. Our seasoned team of advocates combines a profound understanding of both the media and sports landscapes, providing expert counsel on a diverse range of legal issues. In the high-speed, ever-evolving worlds of sports and media, our legal experts are dedicated to guiding clients through the legal intricacies that shape their professional journeys.
                                             <br />Our Media and Sports Law practice adopts a comprehensive approach to serve our clients, which include athletes, artists, sports teams, media companies, and various other entities. We address a multitude of legal issues, including but not limited to:
                                             Navigating the complexities of contracts in the sports and entertainment industry requires a keen eye for detail and a deep understanding of the industry's nuances. Our Advocates excel at assisting clients in securing favorable contractual agreements that protect their interests.
                                              Intellectual property is often the cornerstone of success in media and sports. Our team is well-versed in safeguarding intellectual property rights, including trademarks, copyrights, and patents, ensuring that our clients' creations are protected.
                                             
                                             <br /> 
                                              Crafting successful endorsement deals requires a delicate balance of legal expertise and industry knowledge. Our team assists clients in negotiating and finalizing endorsement agreements that are mutually beneficial and legally sound.
                                             Our unwavering commitment to excellence and our proven track record of success set us apart. We understand that in the realms of media and sports, the pursuit of greatness never stops. Our Advocates are dedicated to helping clients not only achieve their immediate goals but also to guide them through the ever-evolving legal challenges that define this dynamic industry.
                                             <br /> In the worlds of media and sports, change is constant, and the legal landscape can shift rapidly. Our team remains at the forefront of industry developments and regulatory changes. We provide clients with up-to-date legal counsel, ensuring that they are well-informed and able to adapt to the evolving legal environment.
                                             Media and Sports Law at Gaur Legum is a testament to our commitment to empowering excellence in the sports and entertainment industry. When you choose Gaur Legum for your media and sports law needs, you are choosing a dedicated team that is not only well-versed in the intricacies of the industry but also deeply passionate about helping you achieve your professional goals. Your success is our priority, and we are here to provide the legal support and guidance you need to excel in this dynamic and competitive field.

                                             {/* Gaur Legum boasts a dynamic and comprehensive Media and Sports Law practice that caters to the unique legal needs of individuals and entities in the sports and entertainment industry. Our seasoned team of advocates combines a deep understanding of both the media and sports landscapes, offering expert counsel on a wide range of legal issues. In the fast-paced world of sports and media, our legal experts assist clients with contract negotiations, intellectual property rights, endorsement deals, and dispute resolution. We guide athletes, artists, sports teams, and media companies through complex licensing agreements, trademark disputes, and defamation cases, ensuring their interests are safeguarded. With an unwavering commitment to excellence and a track record of success, Our Advocates are dedicated to helping clients achieve their goals while navigating the ever-evolving legal challenges in this dynamic industry.  */}
                                        </Fade>
                                   </p>
                              </div>
                         </div>
                    </div>
               </section>
          </>
     )
}

export default MediaandSports