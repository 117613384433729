import React from 'react'
// import img from '../../../Images/team/OurTem/ajay gaur.jpg'
import img from '../../../Images/people/ajaygaur_pic.png'
import Fade from 'react-reveal/Fade'
const Ajaygaur = () => {
    return (
    <>
        <section className='slider-container' >
            <div className="slides">
                <Fade duration={1000}>
                <img className='slide-image' loading='lazy'  src={img} alt="" />
                </Fade>
            </div>
        </section>
        <section id="about-top" className="py100  about_us_bg" style={{background:"#143d53", color:"#f0f0f0"}}>
            <div className="container" >
                <div className="row">

                    <div className="col-md-12 col-sm-12 col-12">
                        <h1 style={{ textAlign: 'center', fontWeight: "600" , color:"gold", textTransform:"uppercase", fontSize:'2rem'}}>
                         Ajay Gaur
                        </h1>
                        <h4 style={{ textAlign: 'center', fontWeight: "500" , color:"gold"}}>
                            Founder
                        </h4>
                        <p style={{textAlign:'left'}} >
<Fade bottom>
                        Mr. Ajay Gaur saw a dream of being an Advocate in his childhood and then worked meticulously to be what he once really admired as a child. He expresses profound gratitude to God for making his dream a reality. He finds immense joy in his profession, considering it his true passion.
                            <br />He earned his BALLB 5-year integrated degree in 1988 from Maharshi Dayanand University(MDU), Rohtak and got himself enrolled in Bar Council of Delhi in 1988.
                            <br />Bringing an extensive 35+ years of professional background and expertise his enthusiasm has invariably remained sky-high, constant, and consistent with immense optimism which is discernable in him at present as well.
                            <br />Mr. Ajay is a seasoned and proficient professional as he diligently invested his optimum time and energy working unconventionally and single-handedly in the initial years of his profession, appraising various fields to acquire knowledge and experience. Initially, he began with Land Revenue Matters, Landlord-tenant disputes, Property Disputes, Matrimonial Law, Labor Law, and the litigations of some aspects of Management. With expertise, he has settled approximately a thousand Domestic Inquiries and several thousands arbitrations for a wide range of registered corporate societies and finance companies, ensuring successful outcomes in each instance.
                            <br />He domestically has worked with Bharat Sanchar Nigam Limited (BSNL), Oriental Bank of Commerce (OBC), Relaxo Footwears Limited, and Tourism Finance Corporation of India Limited (TFCI). Currently he is an active empaneled advocate for Municipal Corporation of Delhi, Mahanagar Telephone Nigam Limited (MTNL), United India Insurance Company Limited (UIIC) and several other statutory bodies.
                            <br />Since 2011, he has formally gained recognition as a Mediator accredited by the Delhi Dispute Resolution Society (Regd.). Utilizing his expertise, he has facilitated multiple mediations for clients, consistently delivering resolutions that meet their needs and leaving them thoroughly satisfied with the outcomes.
                            <br />From 2014 to 2017, he held the prestigious position of Senior Vice President at the Delhi Bar Association. During this period, he played a significant role in the association's initiatives and contributed significantly to the legal community.
                            <br />He actively serves as an Executive member for numerous trusts and holds the position of trustee in several societies and trusts, demonstrating his commitment to various charitable and organizational endeavors.
                            <br />He serves clients globally, offering counsel through video calls. His expertise extends to representing numerous Non-Resident Indian (NRI) clients in legal disputes in India, including cases related to child adoption, showcasing his expertise in cross-border litigation.
                            <br />He operates alongside a close-knit group of associates, valuing them as an integral part of his extended family. His dedicated efforts are consistently directed toward enhancing the welfare and progress of individuals and society, reflecting his commitment to social advancement. Lastly, he firmly upholds the notion that every day in this profession adds up to the seniority and maturity of a professional, and becoming a professional not only brings power and respect but also innumerable responsibilities and liabilities at the same time.

</Fade>

                        </p>
                    </div>
                    {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="about-top-img animation-img-two">
                        <img alt="" src={img1} />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="about-img">
                        <h2>Our Vision</h2>
                        {/* <p>
                To be the most preferred Infrastructure and Solutions Provider 
                </p>  <h5>Spreading happiness through the Sound and Visual senses.</h5> 
                        <p>
                            Through:
                        </p>

                        <ul>
                            <li><i><FiCheck /></i>Professional Audio Systems Solution</li>
                            <li><i><FiCheck /></i>Professional Video Systems Solution</li>
                            <li><i><FiCheck /></i>Professional Lighting Systems Solution</li>
                            <li><i><FiCheck /></i>Broadcast Systems Solution</li>
                            <li><i><FiCheck /></i>Acoustic Design & Consultancy</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row" style={{ marginTop: "1rem" }} >
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="about-img">
                        <h2>Our Evolution</h2>
                        <p>
                            TCI ZEN is a Pro-Audio, Video, Broadcast, Lighting & Acoustics Design and Consultancy.
                            Since its inception, TCI ZEN have come a long way. From a 'me too' company TCI ZEN has been
                            able to create a niche for itself in the fiercely competitive environment.
                        </p>
                        <p>This has
                            been made possible by incorporating our vision of providing solutions to our business
                            partners to maximize the business benefits by enabling technology.</p>
                        <p>
                            The company formed and managed by professionals who are dreamers,
                            and who dream the most do the most, with the required expertise and know-how.
                            All this with an uncanny ability to sense the future potential.
                        </p>
                        <p>Strong technical
                            expertise is blended with hard-core marketing principles to cultivate a whole new
                            set of ethos, work culture and technological break through. As a result, a better
                            and brighter support to our clients.</p>

                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="about-top-img animation-img-two">
                        <img alt="" src={img2} />
                    </div>
                </div>
            </div> */}
                </div>
            </div>
        </section>
    </>
    )
}

export default Ajaygaur
