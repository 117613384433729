import React, { useRef } from 'react'
import FormInput from "../From/index";
import emailjs from '@emailjs/browser'

const ContactForm = () => {

    const form = useRef();
    
    const sendEmail = (e) => {

        e.preventDefault();


        emailjs.sendForm('service_euniy1e', 'template_iq9vnsh', form.current, 'AtFtxd1aURqUnbMkG')
        .then((result) => {
            console.log(result.status);
            }, (error) => {
                console.log(error.text);
            });
            
    };
    
    return (
        <>
            <div className="contact-form-area box-shadow">
                <form ref={form}>
                    <div className="row">
                        <div className="col-lg-12  col-md-12 col-sm-12 col-12">
                            <FormInput
                                tag={'input'}
                                type={'text'}
                                name={'first_name'}
                                id={'f_name'}
                                classes={'form-control'}
                                placeholder={'Please enter your Full Name *'}
                            />
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                                <FormInput
                                    tag={'input'}
                                    type={'text'}
                                    id={'email'}
                                    name={'user_email'}
                                    classes={'form-control'}
                                    placeholder={'Enter Your Mail Here....'}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12  col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                                <FormInput
                                    tag={'input'}
                                    type={'number'}
                                    id={'num'}
                                    name={'user_num'}
                                    classes={'form-control'}
                                    placeholder={'Enter Your Phone Number Here....'}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                                <FormInput
                                    tag={'textarea'}
                                    name={'message'}
                                    id={'msg'}
                                    classes={'form-control'}
                                    placeholder={'Write Your Message *'}
                                />
                            </div>
                        <div className="other-option col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-submit-button" onClick={sendEmail}>
                                <FormInput
                                    tag={'button'}
                                    val={'Send Message'}
                                    
                                />
                            </div>
                        </div>
                                    </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ContactForm
