import React from 'react'
import '../../assets/css/style.css'
import '../Slider/slider.css'
import img from '../../Images/practice_area/intellectual_1.jpg'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const IntellectualPropertyRights = () => {
    return (
        <>
            <section className='slider-container_prac' >
                <div className="">
                    <Zoom>
                    <img loading="lazy" src={img} alt="" />
                    </Zoom>
                </div>
            </section>
            <section id="about-top" className="py100  about_us_bg">
                <div className="container" >
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600" }}>
                                Intellectual Property Rights
                            </h1>
                            <p style={{ padding: "2rem" }}>
                                <Fade duration={3000}>
                                    In the realm of Intellectual Property Rights (IPR), Gaur Legum's team of dedicated advocates is your trusted partner. We address the entire life cycle of various intellectual property assets, including patents, designs, trademarks, copyrights, plant varieties, domain names, geographical indications, biodiversity issues, trade secrets, and confidential information. Our expertise spans local and international filings, prosecution, advisory services, transactions, and enforcement matters, catering to a diverse clientele.
                                    <br />
                                    Intellectual Property Rights are a critical component of protecting innovation, creativity, and business interests. Our team, comprising both legal and technical experts, collaborates to harness the commercial, strategic, and technical aspects of our clients' intellectual property assets. We provide customized, practical, and cost-effective solutions that cater to the unique needs and goals of our clients.
                                    <br />
                                    Our advocates guide clients through the complex world of patents, ensuring that their inventions are legally protected, and their commercial interests safeguarded.
                                    We provide expert support in design-related matters, helping clients secure and leverage their design assets.

                                    Trademarks are the face of a brand, and our team excels in safeguarding these valuable assets.
                                    Creative works and content deserve protection, and our advocates are skilled at preserving the rights of creators.

                                    In the realm of agriculture, we offer guidance on the protection of plant varieties and the associated legal matters.
                                    The digital world is rife with domain name issues, and we provide solutions for clients navigating these challenges.
                                    Geographical indications are vital for many products, and our team ensures their proper protection.
                                    As biodiversity gains importance, we offer guidance on related legal matters.
                                    Protecting sensitive information is crucial, and our team offers strategies for safeguarding trade secrets and confidential data.
                                    <br />
                                    With expertise in both local and international intellectual property matters, Gaur Legum ensures that our clients are equipped to navigate the complexities of an interconnected world. Whether it's filing a patent, enforcing copyright, or protecting a trademark, our team is here to offer unwavering support and guidance.
                                    Intellectual Property Rights are integral to innovation, creativity, and the success of businesses. At Gaur Legum, we are dedicated to safeguarding the intellectual property assets of our clients and providing them with the legal expertise they need to thrive. When you choose Gaur Legum for your Intellectual Property Rights needs, you are selecting a partner that is deeply committed to your success and the protection of your intellectual property assets. We are here to provide comprehensive legal support and strategic guidance in the ever-evolving world of intellectual property.

                                    </Fade>
                            </p>
                        </div>
                      
                    </div>
                </div>
            </section>
        </>
    )
}

export default IntellectualPropertyRights