import styled from "styled-components";
import { Link } from "react-router-dom";

export const Button = styled(Link)`
background: ${({ primary }) => (primary ? '#000d1a' : 'CD853F' )};
white-space: none;
border: none;
min-width: 100px;
max-width: 150px;
cursor: pointer;
text-decoration: none;
text-transform: uppercase;
transition: 0.3s;
display: flex;
justify-content: center;
align-items: center;
padding: ${({ big }) => (big ? '15px 27px' : '15px 25px')};
color: ${({ primary }) => (primary ? '#fff' : '#000d1a' )};
font-size: ${({ big }) => (big ? '16px' : '12px')};
&:hover{
    transform: translateY(-2px); 
}
`;