import React from 'react'
import '../../assets/css/style.css'
import '../Slider/slider.css'
import img from '../../Images/practice_area/consumer.jpg'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom'
const Consumerlaw = () => {
    return (
        <>
            <div style={{ background: "#f0f0f0" }}>
                <section className='slider-container_prac' >
                    <div className="" style={{overflow:"hidden"}}>
                        <Zoom>
                        <img loading="lazy" src={img} alt="" />
                        </Zoom>
                    </div>
                </section>
            </div>
          
            <section id="about-top" className="py100  about_us_bg">
                <div className="container" >
                    <div className="row">

                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600" }}>
                                Consumer Laws
                            </h1>
                            <p style={{ padding: "2rem" }}>
                                <Fade duration={3000}>

                                    Gaur Legum boasts an extensive Consumer Litigation Practice, underpinned by a team of exceptionally experienced and talented advocates who possess expertise in the crucial Consumer Law Sector. We offer our guidance and representation to a diverse clientele, including individual consumers, developers, various public sector undertakings, and corporate houses. Our range of services spans from the District Forum(s), State Commissions, National Consumer Disputes Redressal Commission (“NCDRC”) to the Hon’ble Supreme Court of India.
                                    <br />
                                         Our staff has garnered extensive experience in addressing complex consumer cases across India. We are renowned for our prowess in crafting pleadings and successfully defending our client's interests in court. Our advocates have a track record of managing a substantial number of complaints in the National Commission. We work closely with our clients to develop long-term solutions while keeping costs and other constraints in mind, whether they are pursuing or defending consumer actions.
                                    
                                    We offer expert guidance on the development and assessment of consumer agreements, ensuring that they are legally sound and protective of consumer rights.
                                    
                                    Our team provides counseling on unfair contracts and unfair trade practices, ensuring that our clients are well-informed about their rights and the legality of contracts they enter.
                                    
                                    <br />
                                    We provide in-depth analysis of potential outcomes in consumer cases and offer expert opinions to guide our clients in their decision-making.
                                    
                                    consumer harm disputes arise, we offer defense and litigation services to protect our clients' interests.
                                    
                                    Our advocates are well-versed in providing legal counsel on all aspects of consumer protection, including product liability.
                                    
                                    We assist clients with warranty claims, ensuring that their rights as consumers are upheld.
                                    
                                    E-commerce is now covered by the Consumer Protection Act, and our team provides guidance in this rapidly evolving field.
                                    
                                    We help consumers and businesses address incorrect or deceptive advertisements and seek remedies.
                                    
                                    <br />
                                    When there is potential for settlement, we support the settlement of disputes through mediation, striving to find mutually acceptable solutions.
                                    
                                    We offer comprehensive support for all litigation-related materials, ensuring that our clients are well-prepared for their legal proceedings.
                                    
                                    Our team is equipped to represent clients in front of the appropriate Consumer Disputes Redressal Forums, ensuring that their cases are skillfully presented.
                                    
                                    Consumer litigation is a vital component of protecting the rights and interests of consumers. At Gaur Legum, we are dedicated to upholding consumer rights and providing unwavering support to our clients. When you choose Gaur Legum for your consumer litigation needs, you are selecting a partner that is not only well-versed in the intricacies of consumer law but is also deeply committed to your success and protection. We are here to provide comprehensive legal support and strategic guidance in navigating the complex landscape of consumer litigation.

                                        </Fade>

                            </p>
                        </div>
                      
                    </div>
                </div>
            </section>
        </>
    )
}

export default Consumerlaw