import img1 from '../../../Images/team/Solid.webp'
import img2 from '../../../Images/team/Solid Photo/c-2.webp'
import img3 from '../../../Images/team/Solid Photo/g-3.webp'
// import img4 from '../../../Images/team/Solid Photo/b-4.webp'
import img5 from '../../../Images/team/Solid Photo/e-5.webp'
import img6 from '../../../Images/team/Solid Photo/A-6.webp'
import img7 from '../../../Images/team/Solid Photo/f-7.webp'
// import img8 from '../../../Images/team/Solid Photo/d-8.webp'
// import author1 from '../../../assets/img/blog/author.png'


export const BlogData = [
  {
    img: img1,
    heading: "Ajay Gaur",
    authorName: "Ajay Gaur",
    position: "Founder",
    category: "Read More",
    link: "/ajaygaur"
  },
  {
    heading: "Om Prakash",
    img: img2,
    authorName: "John Doe",
    position: "Senior Associate",
    category: "Read More",
    link: "/omprakash"
  },
  {
    heading: "Braj Kishore Roy",
    img: img3,
    authorName: "John Doe", position: "Senior Associate",
    category: "Read More",
    link: "/bkroy"
  },
  // {
  //   img: img4,
  //   heading: "Divya Sharma",
  //   position: "Associate",
  //   authorName: "John Doe",
  //   category: "Read More",
  //   link: "/divya"
  // },
  {
    img: img5,
    heading: "Aadhaar Gaur",

    position: "Marketing Head and Associate",
    authorName: "Ajay Gaur",
    category: "Read More",
    link: "/aadhaargaur"
  },
  {
    img: img6,
    heading: "Raujas Sharma  ",
    position: "Associate",
    authorName: "John Doe",
    category: "Read More",
    link: "/raujas"
  },
  {
    img: img7,
    heading: "Kuber Kapoor",
    position: "Associate",
    authorName: "John Doe",
    category: "Read More",
    link: "/kuber"
  },
  // {
  //   img: img8,
  //   heading: "Jyoti",
  //   position: "Associate",
  //   authorName: "John Doe",
  //   category: "Read More",
  //   link: "/jyoti"
  // }
];