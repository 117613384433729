import React from 'react'
import styled from 'styled-components'
import { menuData } from '../Data/MenuData';
import { Link } from 'react-router-dom';
import { Button } from './Button';

const DropDownContainer = styled.div`
position: fixed;
z-index: 999;
width: 100%;
height: 100%;
background: #143d53;
display: grid;
align-items: center;
top: 13px;
left: 0;
transition: 0.3s ease-in-out;
opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`;

const Icon = styled.div`
position: absolute;
top: 1.4rem;
right: 1.5rem;
background: transparent;
font-size: 2rem;
cursor: pointer;
ontline: none;
`;
// const CloseIcon = styled(FaTimes)`
// color: #000d1a;
// `;
const DropdownWrapper = styled.div``;
const DropdownMenu = styled.div`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(5, 80px);
text-align: center;
margin-bottom: 4rem;

@media screen and (max-width: 480px){
grid-template-rows: repeat(5, 60px);
}
`;
const DropdownLink = styled(Link)`
display: flex;
align-items: center;
justify-content: center;
color: #fff;
font-size: 1.5rem;
text-decoration: none;
list-style: none;
color: #fff;
cursor: pointer;
transition: 0.2s ease-in-out;

&:hover{
    color: #000d1a;
}
`;
const BtnWrap = styled.div`
display: flex;
justify-content: center;
align-items:center;
`;

const Dropdown = ({ isOpen, toggle }) => {
    return (
        <div>
            <DropDownContainer isOpen={isOpen} onClick={toggle} >
                <Icon onClick={toggle}>
                    {/* <CloseIcon /> */}
                </Icon>
                <DropdownWrapper>
                    <DropdownMenu>
                        {menuData.map((item, index) => (
                            <DropdownLink to={item.link} key={index}>
                                {item.title}
                            </DropdownLink>
                        ))}
                    </DropdownMenu>
                    <BtnWrap>
                        <Button primary="true" round="true" big="true" to='/contact'>
                            Contact Us
                        </Button>
                    </BtnWrap>
                </DropdownWrapper>
            </DropDownContainer>
        </div>
    )
}

export default Dropdown