import React from 'react'
import '../../../assets/css/style.css'
import img from '../../../Images/team/OurTem/raujas.jpg'

import Fade from 'react-reveal/Fade'
const Raujas = () => {
    return (
        <>
        <section className='slider-container' >
            <div className="slides">
            <Fade duration={1000}> 
                <img className='slide-image' src={img}  loading='lazy' alt="" />
                </Fade> </div>
        </section>
            <section id="about-top" className="py100  about_us_bg" style={{ background: "#143d53", color: "#f0f0f0" }}>
                <div className="container">
                    <div className="row">

                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600", color: "gold", textTransform: "uppercase", fontSize:'2rem'}}>
                               Raujas Sharma
                            </h1>
                            <h4 style={{ textAlign: 'center', fontWeight: "500", color: "gold" }}>
                                Associate
                            </h4>
                            <p>
                            <Fade bottom> 
                                In 2021, Mr. Raujas Sharma got enrolled in the Bar Council of Delhi after completing his five years integrated BALLB degree from GGSIP University.
                                <br/>Raujas's values and aspirations highlight his commitment to social justice and equality. His belief in treating everyone with respect and humility, regardless of their background, reflects a strong sense of empathy.
                                <br/> He aims to support marginalized communities and is dedicated to enforcing laws that improve the lives of stray animals and homeless individuals. He underscores the significance of recognizing and respecting the daily challenges that people go through
                                <br/>Recognizing and addressing these challenges is a fundamental aspect of creating a more inclusive and compassionate society.He exhibits deep social responsibility and empathy by advocating for the marginalized and providing support where it's needed and such commendable commitment lies in his determination to improve society through lawful methods and a justice oriented approach.
                </Fade>
                            </p>
                        </div>
                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-top-img animation-img-two">
                    <img alt="" src={img1} />
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-img">
                    <h2>Our Vision</h2>
                    {/* <p>
            To be the most preferred Infrastructure and Solutions Provider 
            </p>  <h5>Spreading happiness through the Sound and Visual senses.</h5> 
                    <p>
                        Through:
                    </p>

                    <ul>
                        <li><i><FiCheck /></i>Professional Audio Systems Solution</li>
                        <li><i><FiCheck /></i>Professional Video Systems Solution</li>
                        <li><i><FiCheck /></i>Professional Lighting Systems Solution</li>
                        <li><i><FiCheck /></i>Broadcast Systems Solution</li>
                        <li><i><FiCheck /></i>Acoustic Design & Consultancy</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="row" style={{ marginTop: "1rem" }} >
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-img">
                    <h2>Our Evolution</h2>
                    <p>
                        TCI ZEN is a Pro-Audio, Video, Broadcast, Lighting & Acoustics Design and Consultancy.
                        Since its inception, TCI ZEN have come a long way. From a 'me too' company TCI ZEN has been
                        able to create a niche for itself in the fiercely competitive environment.
                    </p>
                    <p>This has
                        been made possible by incorporating our vision of providing solutions to our business
                        partners to maximize the business benefits by enabling technology.</p>
                    <p>
                        The company formed and managed by professionals who are dreamers,
                        and who dream the most do the most, with the required expertise and know-how.
                        All this with an uncanny ability to sense the future potential.
                    </p>
                    <p>Strong technical
                        expertise is blended with hard-core marketing principles to cultivate a whole new
                        set of ethos, work culture and technological break through. As a result, a better
                        and brighter support to our clients.</p>

                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-top-img animation-img-two">
                    <img alt="" src={img2} />
                </div>
            </div>
        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Raujas
