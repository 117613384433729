import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, InputLabel, TextField, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";

const BlogDetails = () => {
  const [blog, setBlog] = useState({});
  const id = useParams().id;
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  // get blog details
  const getBlogDetails = async () => {
    try {
      const { data } = await axios.get(`/api/v1/blog/get-blog/${id}`);
      if (data?.success) {
        setBlog(data?.blog);
        setInputs({
          title: data?.blog.title,
          description: data?.blog.description,
          image: data?.blog.image,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getBlogDetails();
  }, [id]);
  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  //form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(`/api/v1/blog/update-blog/${id}`, {
        title: inputs.title,
        description: inputs.description,
        image: inputs.image,
        user: id,
      });
      if (data?.success) {
        toast.success("Blog Updated");
        navigate("/my-blogs");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
    <div style={{backgroundColor:"#162e66", color:"white", minHeight:"100vh"}}>
      <form onSubmit={handleSubmit}>
        <Box
          width={"80%"}
          padding={3}
          margin="auto"
          display="flex"
          flexDirection={"column"}
        >
          <Box width={"50%"}
          padding={3}
          margin="auto"
          display="flex"
          flexDirection={"column"}>
          <CardMedia component="img" width="100%" className="blog_img_ind" image = {inputs.image}/>
          </Box>
          <Typography variant="h4" textAlign={"center"} marginTop="1rem">
          {inputs.title}
          </Typography>
          <Typography width="100%" variant="h6" textAlign={"justify"} marginLeft="auto" marginRight="auto" marginTop="1rem">
          {inputs.description}
          </Typography>
        </Box>
      </form>
    </div>
    </>
  );
};

export default BlogDetails;
