import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules';
import './test.css'

const data = [
    {
        key: 0,
        name: "Ashok Yadav",
        review: "Gaur Legum firm’s advocates are extremely skilled and devoted. They gave me great legal assistance, ensuring that my issue was successfully resolved. I couldn't have asked for a more accurate representation.",
    },
    {
        key: 1, 
        name: "Abhishek Sharma",
        review: "Gaur Legum outperformed my expectations. Their professionalism, attention to detail, and dedication to the rule of law are unparalleled. I wholeheartedly recommend their legal services.",
    },
    {
        key: 2,
        name: "Aakash Verma",
        review: "Mr. Ajay Gaur deserves my gratitude for his tireless efforts in resolving my challenging legal matter. His knowledge and continuous assistance were critical in attaining a favorable outcome.",
    },
    {
        key: 3, 
        name: "Nikita Garg",
        review: "Gaur Legum is the place to go if you need top-tier legal assistance. Their legal team's knowledge and personalized approach are unrivaled.",
    },
    {
        key: 4, 
        name: "Janhvi Ahuja",
        review: "Gaur Legum firm’s legal services have exceeded my expectations. Their advocates are not only well-versed in the subject matter but also sympathetic and empathetic.",
    },
    {
        key: 5, 
        name: "Beena Das",
        review: "I can't thank Mr. Ajay Gaur enough for his excellent legal counsel. They expertly handled my matter, ensuring a favorable end. Truly amazing!",
    },
    {
        key: 6, 
        name: "Mohd. Faiz",
        review: "Gaur Legum is a shining example of legal excellence. Their advocates are the greatest in the business because of their commitment to their clients and uncompromising attention to the law. ",
    },
    {
        key: 7, 
        name: "Rajesh Gupta",
        review: "Gaur Legum displayed unrivaled professionalism and legal knowledge from the initial consultation to the ultimate result. ",
    },
    {
        key: 8, 
        name: "Manan Kumar",
        review: "Choosing Gaur Legum for my legal needs was the best decision I ever made. Their advocates' knowledge, communication skills, and results are unrivaled. ",
    },
    {
        key: 9, 
        name: "Ritu Nelson",
        review: "I was up against a formidable legal challenge, but Gaur Legum supplied the assistance and knowledge I required to triumph. I will be eternally grateful for their excellent representation.",
    },
    {
        key: 10, 
        name: "Sharad Kapoor",
        review: "Gaur Legum is the ultimate standard when it comes to legal concerns. Their advocates' sincerity, knowledge, and commitment to justice are extremely inspiring. Strongly recommended!",
    },
]

const Testimonialscards = () => {
    return (
        <section id='testimonials' style={{ paddingTop: "2rem", paddingBottom: "3rem", background: "#143d53", color: "#e0e0e0" }}>
            <h1 style={{ paddingBottom: "2rem" }}>Testimonials</h1>
            <Swiper
                spaceBetween={30}
                slidesPerView={3}
                centeredSlides={false}
                autoplay={{
                    delay: 1000,
                }}
                // pagination={{
                //     clickable: true,
                // }}
                modules={[Autoplay]}
                className="container testimonials_container mySwiper">
                {
                    data.map(({ name, review , key}, index) => {
                        return (
                            <div  key={key} >
                                <SwiperSlide className='testimonials' key={key}>
                                    {/* <div className='client_avatar'>
                                    </div> */}
                                    <h4 className='client_name' style={{ fontWeight: "bolder" }}>{name}</h4>
                                    <small className='client_review' style={{ textAlign: "left" }}>{review}</small>
                                </SwiperSlide>
                            </div>
                        )
                    })
                }
            </Swiper>
        </section>
    )
}

export default Testimonialscards
