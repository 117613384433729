import React from 'react'
import BlogCard from '../Home/Blog/BlogCard'
// import SectionHeading from '../Home/SectionHeading/SectionHeading'
import { BlogData } from '../Home/Blog/BlogData'
// import { colors } from '@material-ui/core'


const Peoples = ({className=""}) => {
     return (
          <>
               <div style={{paddingTop:"4rem", background:"#000"}}>

                    <section id="blog-area" className={`py100 ${className}`}>
                         <div className="container">
                         <h1 style={{ textAlign: 'center', fontWeight:"600", color:"white" }}>
                                Our People
                            </h1>
                              <div className="row">
                                   {BlogData.map((data, index) => (<BlogCard data={data} key={index} />))}
                              </div>
                         </div>
                    </section>
               </div>
          </>
     )
}

export default Peoples
