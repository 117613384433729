import React from 'react'
import '../../assets/css/style.css'
import '../Slider/slider.css'
import img from '../../Images/practice_area/immigration.png'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';


const ImmigrationLaw = () => {
    return (
        <>
            <section className='slider-container_prac' >
                <div className="">
                    <Zoom>
                    <img loading="lazy" src={img} alt="" />
                    </Zoom>
                </div>
            </section>
            
            <section id="about-top" className="py100  about_us_bg">
                <div className="container" >
                    <div className="row">

                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600" }}>
                                Immigration Law
                            </h1>
                            <p style={{ padding: "2rem" }}>
                                <Fade duration={3000}>
                                    Immigration issues are far from light matters. The outcome of an immigration appeal case can have a profound impact not only on an individual's life but also on the lives of their family. When faced with such circumstances, the only recourse is to approach the challenge logically, rationally, and assertively in a court of law.
                                    <br />
                                    At Gaur Legum, our team of Advocates provides a wide range of legal services related to immigration law. We understand the complexity and importance of immigration matters and are here to offer guidance and support throughout the entire process, from providing immigration law advice to assisting with the filing of immigrant and non-immigrant visas and providing specialized legal services for filing and conducting immigration appeals.
                                    <br />
                                    Our Advocates are well-versed in the intricacies of immigration rules, not only in India but also in other nations throughout the world. With years of expertise under our belt, we offer the best legal services for the benefit of our clients. Our law firm aids in the submission of applications, representations, forms, and other documents related to various immigration departures worldwide.
                                    
                                    Our comprehensive immigration law services cover a wide range of areas, ensuring that we can address the diverse needs of our clients, we provide expert guidance on navigating the intricacies of immigration law, helping individuals and families make informed decisions.
                                    Our team assists in the filing of immigrant and non-immigrant visas, ensuring that the application process is smooth and compliant with legal requirements.
                                    When faced with an immigration appeal, we are well-equipped to provide specialized legal services, ensuring that the appeal is handled logically, rationally, and assertively in court.
                                    <br />
                                    Immigration matters are often fraught with complexities, from navigating the legal requirements to addressing unique individual circumstances. Having a dedicated team of Advocates by your side can make a significant difference in the outcome of your immigration case. We understand that immigration challenges can be emotionally and logistically taxing, and we are here to provide support and guidance to ensure the best possible outcome for our clients.
                                    <br />
                                    Immigration challenges are significant and often life-altering. At Gaur Legum, we are committed to providing unwavering support to our clients as they navigate the complexities of immigration law. When you choose Gaur Legum for your immigration law needs, you are selecting a team that is not only well-versed in the intricacies of immigration law but is also deeply committed to your success and the well-being of your family. We are here to provide the comprehensive legal support and guidance you need to navigate the challenges of immigration and ensure a successful journey.

                                    {/* Immigration issues must not be handled lightly. The outcome of an immigration appeal case can have a detrimental influence not only on the person's life but also on the lives of their family. In such a case, the only way out is to oppose it logically, rationally, and forcefully in court.
                         <br />
                         <br />
                         Our team of advocates provides a wide range of immigration law-related legal services, including immigration law advice, support in filing immigrant and non-immigrant visas, and specialized legal services for Filing and Conducting Immigration Appeals. The advocates are well-versed in the intricacies of immigration rules not just in India, but also in other nations throughout the world. With many years of expertise under our belt, we provide the best legal services for the benefit of our clients. Our law firm aids in the submission of applications, representations, forms, and other documents related to various immigration departures around the world. */}
                                </Fade>
                            </p>
                        </div>
                      
                    </div>
                </div>
            </section>
        </>
    )
}

export default ImmigrationLaw