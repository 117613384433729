
import React from "react";
import Fade from 'react-reveal/Fade';
function SliderContent({ activeIndex, sliderImage }) {
  // const props = useSpring({
  //   opacity: 1,
  //   transform: "translate(0px, 0px)",
  //   from: { opacity: 0, transform: "translate(-20px, -20px)" }
  // });
  // const styles = useSpring({
  //   from: { transform:"translateX(-10px)" },
  //   to: { transform:"translateX(0px)"},
  //   config: { duration: "500" }
  // })
  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={index === activeIndex ? "slides active" : "inactive"}
        >
          <img className="slide-image" src={slide.urls} alt="" />
            {/* <h2 className="slide-title">{slide.title}</h2> */}
            <Fade left cascade > 
          <h2 className="slide-text font-weight-bolder">{slide.description}</h2>
            </Fade>
        </div>
      ))}
    </section>
  );
}

export default SliderContent;
