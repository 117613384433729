import React from 'react'
import { Link } from 'react-router-dom'

import Fade from 'react-reveal/Fade';
const BlogCard = (props) => {
    return (
        <Fade bottom>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 hov_team">
                <div className="blog-item single-item-mt-2 hov_team" >
                    <Link to={props.data.link} style={{ textDecoration: "none", textAlign: "center" }}>
                        <div className="blog-img">
                            <Link to={props.data.link}>
                                <img loading='lazy' alt={props.data.heading} src={props.data.img} />
                                </Link>
                        </div>
                        <div className="blog-details">
                            <h3 style={{ margin: "20px  0" }} >
                                <Link to={props.data.link} style={{ textDecoration: "none", color: "white" }}>{props.data.heading}</Link>
                            </h3>
                            <p style={{ textAlign: "center", textDecoration: 'none' }}>
                                {props.data.position}
                            </p>

                            <div className="blog-author-details">
                                <div className="author-img pt10">
                                    <Link to={props.data.link}>
                                          </Link>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </Fade>
    )
}

export default BlogCard
