import First from "../../Images/Images2/1.webp";
import Second from "../../Images/Images2/2.webp";
// import Third from "../../Images/Images2/3.jpg";
// import Forth from "../../Images/Images2/4.jpg";
import Sir1 from "../../Images/Images2/5.jpg";
import Sir2 from "../../Images/Images2/6.jpg";


// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    title: "",
    description: "Your case, our commitment, justice served.",
    urls: Sir2,
  },
  // {
  //   title: "",
  //   description: "Guardians of your rights, defenders of justice.",
  //   urls: Second,
  // },
  {
    title: "",
    description: "Your legal matters, our relentless pursuit.",
    urls: Second,
  },
  {
    title: "",
    description: "Your peace of mind, our priority.",  
    urls: Sir1,
  },
  {
    title: "",
    description: "Where integrity meets expertise",  
    urls: First,
  }
];
