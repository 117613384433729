import React from 'react'
import '../../../assets/css/style.css'
// import img from '../../../Images/team/OurTem/om prakash.jpg'
import img from '../../../Images/people/omprakash.png'

import Fade from 'react-reveal/Fade'
const Omprakash = () => {
    return (
        <>
            <section className='slider-container' >
                <div className="slides">
                    <Fade duration={1000}>
                        <img className='slide-image' src={img} loading='lazy' alt="" />
                    </Fade>            </div>
            </section>
            <section id="about-top" className="py100  about_us_bg" style={{ background: "#143d53", color: "#f0f0f0" }}>
                <div className="container" >
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600", color: "gold", textTransform: "uppercase", fontSize: '2rem' }}>
                                Om Prakash
                            </h1>
                            <h4 style={{ textAlign: 'center', fontWeight: "500", color: "gold" }}>
                                Senior Associate
                            </h4>
                            <p>
                            <Fade bottom> 
                                Mr. Om Prakash is a dedicated and accomplished Advocate, with a proven track record. Demonstrating regard in assisting others by leading a life that is selfless. Enthusiastic to assist with essential resources.
                               <br />Envisions law as a positive and imperative component in various walks of life. After earning a B.Sc. Hons. in Zoology and M.Sc. in Zoology from Magadh University, Bihar in 1984 and 1989 respectively and LLB from University of Delhi in 1997, he got himself enrolled in the Bar Council of Delhi in 2004.
                               <br />He possesses a wealth of experience spanning more than 19 years in the legal field and his expertise spans a wide range of legal areas in the matters of Inheritance and Real Estate, Testamentary Documents, Family Disputes and other Civil Litigations. He is of the opinion that every favorable decision rendered by the Honourable Court, advancing justice for his clients, serves as a reward and testament to his dedication and expertise.
                               <br />For him, good moral conduct are of paramount importance as they signify exhibiting behavior in a professional manner while maintaining composure under pressure, making charitable assumptions about others, showing respect for them, and demonstrating empathy.
                </Fade>
                            </p>
                        </div>
                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-top-img animation-img-two">
                    <img alt="" src={img1} />
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-img">
                    <h2>Our Vision</h2>
                    {/* <p>
            To be the most preferred Infrastructure and Solutions Provider 
            </p>  <h5>Spreading happiness through the Sound and Visual senses.</h5> 
                    <p>
                        Through:
                    </p>

                    <ul>
                        <li><i><FiCheck /></i>Professional Audio Systems Solution</li>
                        <li><i><FiCheck /></i>Professional Video Systems Solution</li>
                        <li><i><FiCheck /></i>Professional Lighting Systems Solution</li>
                        <li><i><FiCheck /></i>Broadcast Systems Solution</li>
                        <li><i><FiCheck /></i>Acoustic Design & Consultancy</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="row" style={{ marginTop: "1rem" }} >
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-img">
                    <h2>Our Evolution</h2>
                    <p>
                        TCI ZEN is a Pro-Audio, Video, Broadcast, Lighting & Acoustics Design and Consultancy.
                        Since its inception, TCI ZEN have come a long way. From a 'me too' company TCI ZEN has been
                        able to create a niche for itself in the fiercely competitive environment.
                    </p>
                    <p>This has
                        been made possible by incorporating our vision of providing solutions to our business
                        partners to maximize the business benefits by enabling technology.</p>
                    <p>
                        The company formed and managed by professionals who are dreamers,
                        and who dream the most do the most, with the required expertise and know-how.
                        All this with an uncanny ability to sense the future potential.
                    </p>
                    <p>Strong technical
                        expertise is blended with hard-core marketing principles to cultivate a whole new
                        set of ethos, work culture and technological break through. As a result, a better
                        and brighter support to our clients.</p>

                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-top-img animation-img-two">
                    <img alt="" src={img2} />
                </div>
            </div>
        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Omprakash
