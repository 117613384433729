import React from 'react'
import '../../assets/css/style.css'
import '../Slider/slider.css'
import img from '../../Images/practice_area/family.png'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const FamilyLaw = () => {
    return (
        <>
            <section className='slider-container_prac' >
                <div className="">
                    <Zoom>
                        <img loading="lazy" src={img} alt="" />
                    </Zoom>
                </div>
            </section>
            
            <section id="about-top" className="py100  about_us_bg">
                <div className="container" >
                    <div className="row">

                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 style={{ textAlign: 'center', fontWeight: "600" }}>
                                Divorce & Family Law
                            </h1>
                            <p style={{ padding: "2rem" }}>

                                <Fade duration={3000}>

                                    At Gaur Legum, our team stands as a collective of the country's most distinguished divorce law advocates, each possessing an exceptional depth of knowledge in marital and family law. It is this extensive experience that sets us apart from others, allowing us to provide you with the optimal route for your legal needs based on decades of knowledge garnered from dealing with countless cases.
                                    Family law is a specialized area that revolves around domestic relationships, encompassing a range of matters such as adoption, inheritance, partition, child custody, and much more.
                                    
                                <br/>
                                     It is within these intimate and personal matters that the expertise and knowledge of our family advocate’s shine. Our team is well-equipped to represent clients in family court hearings or related discussions, drawing on their skills in preparing key legal documents.
                                    Family law is a broad and multifaceted practice area, considering the deeply personal nature of the issues it addresses. Therefore, choosing a trustworthy legal professional to represent you is imperative to ensure that your loved ones are appropriately represented and safeguarded during any legal process.
                                <br/>
                                    Our family law advocates at Gaur Legum are adept at handling a wide spectrum of family law matters, with a particular focus on divorce proceedings and other related issues. Our Advocates team offers unwavering support and guidance for all your divorce and family law needs, backed by extensive experience in divorce and family law disputes. We are dedicated to protecting our clients' interests in every way possible, from creating impeccable legal paperwork to assertively representing your interests in court.
                                    Our comprehensive legal support in family law includes, our team is highly experienced in guiding clients through the complexities of divorce proceedings, ensuring that their rights and interests are protected.
                                    Child custody can be a particularly delicate issue, and our family advocates are skilled at ensuring the best interests of the child are upheld.
                                <br/>
                                    We offer expert guidance on matters related to inheritance and partition, helping clients navigate these intricate legal processes.
                                    Our team is well-versed in adoption-related legal matters, supporting families in their journey through the adoption process.
                                    Family law issues are personal, emotionally charged, and often complex. We understand the importance of having a dedicated and experienced legal team by your side to navigate these intricate matters. When you choose Gaur Legum for your family law needs, you are selecting a team that is not only well-versed in the intricacies of family law but is also deeply committed to your success and the well-being of your loved ones. We are here to provide the comprehensive legal support and unwavering guidance you need to address and resolve family law matters with professionalism and care.

                                    </Fade>
                            </p>
                        </div>
                      
                    </div>
                </div>
            </section>
        </>
    )
}

export default FamilyLaw 