//
import Routers from "./routes/Routers";
import "./Style/style.css";
import "./App.css";
import React, { useState, useEffect } from "react";
import ScrollToTop from "./Components/ScrollToTop";
import { Button, Modal } from "react-bootstrap";
function App() {
  const [showModal, setShowModal] = useState(false);

  const handleModalShow = () => setShowModal(true);
  const handleModalClose1 = () => setShowModal(false);
  const handleModalAccept = () => {
    setShowModal(false);
  };
  const handleModalClose2 = () => {
    window.location.href = "https://google.com/";
  };
  useEffect(() => {
    handleModalShow();
  }, []);
  return (
    <>
      <ScrollToTop />
      <div >
        <Modal show={showModal} onHide={handleModalClose1}>
          <Modal.Header>
            <h1 style={{textAlign:"center"}}>Disclaimer</h1>
          </Modal.Header>
          <Modal.Body>
            <p style={{textAlign:'left'}}>
              The Bar Council of India does not permit advertisement or
              solicitation by advocates in any form or manner. By accessing this
              website, www.gaurlegum.com, you acknowledge and confirm that you are
              seeking information relating to Gaur Legum of your own accord and that
              there has been no form of solicitation, advertisement or
              inducement by Gaur Legum or its members. The content of this
              website is for informational purposes only and should not be
              interpreted as soliciting or advertisement. No
              material/information provided on this website should be construed
              as legal advice. Gaur Legum shall not be liable for consequences of any
              action taken by relying on the material/information provided on
              this website. The contents of this website are the intellectual
              property of Gaur Legum.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleModalAccept}>
              Accept
            </Button>
            <Button variant="danger" onClick={handleModalClose2}>
              Decline
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <Routers />
    </>
  );
}

export default App;
